import { makeStyles } from '@mui/styles';

export const useFormStyles = makeStyles((theme) => ({
    fieldsGroup: {
        display: 'flex',
        flexFlow: 'wrap',
        '& > *': { marginRight: theme.spacing(1) },
        '& > *:last-child': { marginRight: 0 },
    },
    wideNumber: { minWidth: 250 },
}));
