import { makeAutoObservable } from 'mobx';
import { api } from '../lib/api';

class DictionariesStore {
    constructor() {
        this.forestries = [];
        this.allotment_categories = [];
        this.farms = [];
        this.forest_types = [];
        this.structures = [];
        makeAutoObservable(this);
    }

    setDict(dictName, data) {
        this[dictName] = data;
    }

    async loadDictionaries() {
        let tryCount = 0;
        let loaded = false;

        const that = this;

        const load = async function () {
            if (loaded) {
                return;
            }

            try {
                let data = await api.dictionaries({ throwError: true });

                if (data) {
                    loaded = true;
                    for (let dictName in data) {
                        that.setDict(dictName, data[dictName]);
                    }
                }
            } catch (e) {
                tryCount++;

                if (tryCount < 100000) {
                    let pause = 2000;

                    if (tryCount > 5) {
                        pause = 5000;
                    }

                    if (tryCount > 15) {
                        pause = 15000;
                    }

                    if (tryCount > 50) {
                        pause = 60000;
                    }

                    setTimeout(load, pause);
                } else {
                    console.error('Maximum try number of dictionaries load exceeded');
                    throw e;
                }
            }
        };

        return await load();
    }
}

const dictionariesStore = new DictionariesStore();

export { dictionariesStore };
