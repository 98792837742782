import React from 'react';

import { makeStyles } from '@mui/styles';
import { Button, FormHelperText, IconButton, LinearProgress, Link, useTheme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { humanFileSize } from '../../lib/utils';

const useStyles = makeStyles((theme) => ({
    fileHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));

function FileUpload({ file, onDelete = () => null }) {
    const classes = useStyles();

    let uploadProgress = 0;

    if (file.loadedPercent) {
        uploadProgress = file.loadedPercent;
    }

    if (file.url || !file.File) {
        uploadProgress = 100;
    }

    return (
        <div>
            <div className={classes.fileHeader}>
                <div style={{ flexGrow: 1 }}>
                    {file.url ? (
                        <Link href={file.url} download>
                            {file.name} ({humanFileSize(file.size)})
                        </Link>
                    ) : (
                        file.name + ' (' + humanFileSize(file.size) + ')'
                    )}
                </div>
                <div>
                    <IconButton onClick={() => onDelete(file)}>
                        <DeleteIcon />
                    </IconButton>
                </div>
            </div>
            <LinearProgress variant="determinate" value={uploadProgress} />
            {file.error ? <FormHelperText error>{file.error}</FormHelperText> : null}
        </div>
    );
}

function FormFileListUpload({ files, onAppend = () => null, onDelete = () => null, loading }) {
    const theme = useTheme();

    function handleDelete(deleteFile) {
        onDelete(deleteFile);
    }

    function handleAppendFiles(e) {
        let newFiles = [];

        for (let file of e.target.files) {
            newFiles.push({
                name: file.name,
                type: file.type,
                size: file.size,
                keyId: Math.random(),
                File: file,
            });
        }

        onAppend(newFiles);
    }

    return (
        <div style={{ maxWidth: theme.breakpoints.values.sm }}>
            {files && files.length
                ? files.map((file) => (
                      <FileUpload key={file.keyId} file={file} onDelete={(fileData) => handleDelete(fileData)} />
                  ))
                : null}
            <div
                style={{
                    marginTop: theme.spacing(1),
                    marginBottom: theme.spacing(1),
                }}
            >
                <label>
                    <input style={{ display: 'none' }} type="file" onChange={handleAppendFiles} value="" multiple />
                    <Button size="small" variant="outlined" disabled={loading} component="span">
                        Прикрепить файлы
                    </Button>
                </label>
            </div>
        </div>
    );
}

export { FormFileListUpload };
