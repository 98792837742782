import React from 'react';

import { makeStyles } from '@mui/styles';
import { Typography, Avatar, Box, Link } from '@mui/material';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import { Login } from './Login';
import { Reminder } from './Reminder';

const useStyles = makeStyles((theme) => ({
    loginIcon: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

function LoginDialog() {
    const classes = useStyles();
    const [loginAction, setLoginAction] = React.useState('login');

    return (
        <div className={classes.dialogContent}>
            <Avatar className={classes.loginIcon}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h4">
                ЛЕС
            </Typography>
            <Typography variant="body1" color="primary" paragraph>
                Система управления лесным хозяйством
            </Typography>
            {loginAction === 'login' && (
                <>
                    <Login />
                    <Box mt={1} mb={1}>
                        <Link component="button" onClick={() => setLoginAction('reminder')} variant="body2">
                            Забыли пароль?
                        </Link>
                    </Box>
                </>
            )}
            {loginAction === 'reminder' && (
                <>
                    <Reminder />
                    <Box mt={1} mb={1}>
                        <Link component="button" onClick={() => setLoginAction('login')} variant="body2">
                            Войти в систему
                        </Link>
                    </Box>
                </>
            )}
        </div>
    );
}

export { LoginDialog };
