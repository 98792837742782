import React from 'react';

import { observer } from 'mobx-react-lite';

import { DataForm, useDataForm } from '../../../components/common/DataForm';
import { ForestriesSelect } from '../../../components/ForestriesSelect';
import { QuartersSelect } from '../../../components/QuartersSelect';
import { api } from '../../../lib/api';
import { FarmsSelect } from '../../../components/FarmsSelect';
import { AllotmentCategoriesSelect } from '../../../components/AllotmentCategoriesSelect';
import { ALLOTMENT_CATEGORY_PROTECTED, FELLING_FORM_SELECTION_ID } from '../../../constants/dictionaries';
import { FormAutocomplete } from '../../../components/common/FormAutocomplete';
import { FellingKindsSelect } from '../../../components/FellingKindsSelect';
import { SelectionPercentSelect } from '../../../components/SelectionPercentSelect';
import { AllotmentsSelect } from '../../../components/AllotmentsSelect';
import { FormNumber } from '../../../components/common/FormNumber';
import { Box, FormControl, FormLabel } from '@mui/material';
import { humanNumber, Validator } from '../../../lib/utils';

import { useFormStyles } from '../../../hooks/useFormStyles';

export const SelectAllotments = observer(({ setLoading, onClose = () => null, onSave = () => null }) => {
    const classes = useFormStyles();

    const [quarters, setQuarters] = React.useState([]);
    const [loadingQuarters, setLoadingQuarters] = React.useState(false);

    const [farms, setFarms] = React.useState([]);
    const [loadingFarms, setLoadingFarms] = React.useState(false);

    const [categories, setCategories] = React.useState([]);
    const [loadingCategories, setLoadingCategories] = React.useState(false);

    const [categoryComments, setCategoryComments] = React.useState([]);
    const [loadingCategoryComments, setLoadingCategoryComments] = React.useState(false);

    const [fellingKinds, setFellingKinds] = React.useState([]);
    const [loadingFellingKinds, setLoadingFellingKinds] = React.useState(false);

    const [selectionPercents, setSelectionPercents] = React.useState([]);
    const [loadingSelectionPercents, setLoadingSelectionPercents] = React.useState(false);

    const [allotments, setAllotments] = React.useState([]);
    const [loadingAllotments, setLoadingAllotments] = React.useState(false);

    const { data, setErrors, formProps, fieldProps } = useDataForm({
        handleChangeMiddleware: ({ name, newData }) => {
            if (name === 'forestry_id' || !newData.forestry_id) {
                newData.quarter = '';
            }

            if (name === 'quarter' || !newData.quarter) {
                newData.farm_id = '';
            }

            if (name === 'farm_id' || !newData.farm_id) {
                newData.category_id = '';
            }

            if (name === 'category_id' || !newData.category_id) {
                newData.category_comments = '';
                newData.felling_kind_id = '';
            }

            if (
                name === 'category_comments' ||
                (+newData.category_id === ALLOTMENT_CATEGORY_PROTECTED && !newData.category_comments)
            ) {
                newData.felling_kind_id = '';
            }

            if (name === 'felling_kind_id' || !newData.felling_kind_id) {
                newData.selection_percent = '';
                newData.allotment_id = [];
            }

            if (
                name === 'selection_percent' ||
                (+newData.felling_kind_id === FELLING_FORM_SELECTION_ID && !newData.selection_percent)
            ) {
                newData.allotment_id = [];
            }

            return { newData };
        },
    });

    React.useEffect(() => {
        let discard = false;

        (async () => {
            if (data.forestry_id) {
                try {
                    setLoading(true);
                    setLoadingQuarters(true);
                    let res = await api.allotmentsQuarters(data.forestry_id);
                    setQuarters(res ?? []);
                } finally {
                    if (!discard) {
                        setLoadingQuarters(false);
                        setLoading(false);
                    }
                }
            }
        })();

        return () => (discard = true);
    }, [setLoading, data.forestry_id]);

    React.useEffect(() => {
        let discard = false;

        (async () => {
            if (data.forestry_id && data.quarter) {
                try {
                    setLoading(true);
                    setLoadingFarms(true);
                    let res = await api.allotmentsFarms(data.forestry_id, data.quarter);
                    setFarms(res ?? []);
                } finally {
                    if (!discard) {
                        setLoadingFarms(false);
                        setLoading(false);
                    }
                }
            }
        })();

        return () => (discard = true);
    }, [setLoading, data.forestry_id, data.quarter]);

    React.useEffect(() => {
        let discard = false;

        (async () => {
            if (data.forestry_id && data.quarter && data.farm_id) {
                try {
                    setLoading(true);
                    setLoadingCategories(true);
                    let res = await api.allotmentsCategories(data.forestry_id, data.quarter, data.farm_id);
                    setCategories(res ?? []);
                } finally {
                    if (!discard) {
                        setLoadingCategories(false);
                        setLoading(false);
                    }
                }
            }
        })();

        return () => (discard = true);
    }, [setLoading, data.forestry_id, data.quarter, data.farm_id]);

    React.useEffect(() => {
        let discard = false;

        (async () => {
            if (
                data.forestry_id &&
                data.quarter &&
                data.farm_id &&
                data.category_id &&
                +data.category_id === ALLOTMENT_CATEGORY_PROTECTED
            ) {
                try {
                    setLoading(true);
                    setLoadingCategoryComments(true);
                    let res = await api.allotmentsCategoryComments(
                        data.forestry_id,
                        data.quarter,
                        data.farm_id,
                        data.category_id
                    );
                    setCategoryComments(res ?? []);
                } finally {
                    if (!discard) {
                        setLoadingCategoryComments(false);
                        setLoading(false);
                    }
                }
            }
        })();

        return () => (discard = true);
    }, [setLoading, data.forestry_id, data.quarter, data.farm_id, data.category_id]);

    React.useEffect(() => {
        let discard = false;

        (async () => {
            if (
                data.forestry_id &&
                data.quarter &&
                data.farm_id &&
                data.category_id &&
                (+data.category_id !== ALLOTMENT_CATEGORY_PROTECTED || data.category_comments)
            ) {
                try {
                    setLoading(true);
                    setLoadingFellingKinds(true);
                    let res = await api.allotmentsFellingKinds(
                        data.forestry_id,
                        data.quarter,
                        data.farm_id,
                        data.category_id,
                        data.category_comments
                    );
                    setFellingKinds(res ?? []);
                } finally {
                    if (!discard) {
                        setLoadingFellingKinds(false);
                        setLoading(false);
                    }
                }
            }
        })();

        return () => (discard = true);
    }, [setLoading, data.forestry_id, data.quarter, data.farm_id, data.category_id, data.category_comments]);

    React.useEffect(() => {
        let discard = false;

        (async () => {
            if (
                data.forestry_id &&
                data.quarter &&
                data.farm_id &&
                data.category_id &&
                (+data.category_id !== ALLOTMENT_CATEGORY_PROTECTED || data.category_comments) &&
                data.felling_kind_id
            ) {
                try {
                    setLoading(true);
                    setLoadingSelectionPercents(true);
                    let res = await api.allotmentsSelectionPercents(
                        data.forestry_id,
                        data.quarter,
                        data.farm_id,
                        data.category_id,
                        data.category_comments,
                        data.felling_kind_id
                    );
                    setSelectionPercents(res ?? []);
                } finally {
                    if (!discard) {
                        setLoadingSelectionPercents(false);
                        setLoading(false);
                    }
                }
            }
        })();

        return () => (discard = true);
    }, [
        setLoading,
        data.forestry_id,
        data.quarter,
        data.farm_id,
        data.category_id,
        data.category_comments,
        data.felling_kind_id,
    ]);

    React.useEffect(() => {
        let discard = false;

        (async () => {
            if (
                data.forestry_id &&
                data.quarter &&
                data.farm_id &&
                data.category_id &&
                (+data.category_id !== ALLOTMENT_CATEGORY_PROTECTED || data.category_comments) &&
                data.felling_kind_id &&
                (+data.felling_kind_id !== FELLING_FORM_SELECTION_ID || data.selection_percent)
            ) {
                try {
                    setLoading(true);
                    setLoadingAllotments(true);
                    let res = await api.allotmentsList({
                        filterData: {
                            forestry_id: data.forestry_id,
                            quarter: data.quarter,
                            farm_id: data.farm_id,
                            category_id: data.category_id,
                            category_comments: data.category_comments,
                            felling_kind_id: data.felling_kind_id,
                            selection_percent: data.selection_percent,
                        },
                        orderBy: 'number',
                    });

                    setAllotments(res && res?.rows ? res.rows : []);
                } finally {
                    if (!discard) {
                        setLoadingAllotments(false);
                        setLoading(false);
                    }
                }
            }
        })();

        return () => (discard = true);
    }, [
        setLoading,
        data.forestry_id,
        data.quarter,
        data.farm_id,
        data.category_id,
        data.category_comments,
        data.felling_kind_id,
        data.selection_percent,
    ]);

    const fellingKindsDisabled =
        !data.category_id || (+data.category_id === ALLOTMENT_CATEGORY_PROTECTED && !data.category_comments);

    const fellingKind = fellingKinds.find((kind) => +kind.id === +data?.felling_kind_id);

    const allotmentsDisabled =
        !data.felling_kind_id || (+data.felling_kind_id === FELLING_FORM_SELECTION_ID && !data.selection_percent);

    const saveButtonDisabled = allotmentsDisabled || !data?.allotment_id?.length;

    let dataAllotments = allotments.filter((item) => data?.allotment_id?.indexOf(item.id) >= 0);
    dataAllotments.sort((a, b) => a.number - b.number);
    dataAllotments = dataAllotments.map((atm, i) => ({
        ...atm,
        selection_square: data?.allotments?.[i]?.selection_square,
    }));

    function handleSave() {
        const validator = new Validator(data);

        for (let i in dataAllotments) {
            validator.checkAll([{ ['allotments.' + i + '.selection_square']: 'required' }]);
        }

        setErrors(validator.getErrors());

        if (!validator.isHasErrors()) {
            onSave({
                forestry_id: data.forestry_id,
                quarter: data.quarter,
                farm_id: data.farm_id,
                category_id: data.category_id,
                category_comments: data.category_comments,
                felling_kind_id: data.felling_kind_id,
                selection_percent: data.selection_percent,
                allotments: dataAllotments,
            });
        }
    }

    const allotmentsList = allotments.map((v) => ({ id: v.id, name: v.number + '' }));

    return (
        <DataForm
            {...formProps()}
            onSave={handleSave}
            onClose={onClose}
            titleText="Выберите выделы для создания лесосеки"
            saveButtonText="Выбрать выделы"
            saveButtonDisabled={saveButtonDisabled}
        >
            <div className={classes.fieldsGroup}>
                <ForestriesSelect {...fieldProps('forestry_id')} required />
            </div>
            <div className={classes.fieldsGroup}>
                <div className={classes.fieldsGroup}>
                    <QuartersSelect
                        {...fieldProps('quarter')}
                        loading={loadingQuarters}
                        disabled={!data.forestry_id}
                        list={quarters}
                        required
                    />
                    <FarmsSelect
                        {...fieldProps('farm_id')}
                        loading={loadingFarms}
                        disabled={!data.quarter}
                        list={farms}
                        required
                    />
                </div>
                <div className={classes.fieldsGroup}>
                    <AllotmentCategoriesSelect
                        {...fieldProps('category_id')}
                        loading={loadingCategories}
                        disabled={!data.farm_id}
                        list={categories}
                        required
                    />
                </div>
            </div>
            {+data.category_id === ALLOTMENT_CATEGORY_PROTECTED ? (
                <div>
                    <FormAutocomplete
                        {...fieldProps('category_comments')}
                        loading={loadingCategoryComments}
                        disabled={!data.category_id}
                        list={categoryComments}
                        label="Категория защитности"
                        fullWidth
                        required
                    />
                </div>
            ) : null}
            <div className={classes.fieldsGroup}>
                <FellingKindsSelect
                    {...fieldProps('felling_kind_id')}
                    loading={loadingFellingKinds}
                    disabled={fellingKindsDisabled}
                    list={fellingKinds}
                    required
                />
                {+fellingKind?.form_id === FELLING_FORM_SELECTION_ID ? (
                    <SelectionPercentSelect
                        {...fieldProps('selection_percent')}
                        loading={loadingSelectionPercents}
                        disabled={!data.felling_kind_id}
                        list={selectionPercents}
                        required
                    />
                ) : null}
            </div>
            <div className={classes.fieldsGroup}>
                <AllotmentsSelect
                    {...fieldProps('allotment_id')}
                    loading={loadingAllotments}
                    disabled={allotmentsDisabled}
                    list={allotmentsList}
                    fullWidth
                    required
                />
            </div>
            {dataAllotments.map((v, index) => (
                <FormControl key={v.id}>
                    <FormLabel style={{ display: 'flex', alignItems: 'center' }}>
                        <Box mr={1}>
                            Выдел №{v.number} [{humanNumber(v.square)} га]:
                        </Box>
                        <FormNumber {...fieldProps(`allotments.${index}.selection_square`)} label="Площадь (га)" />
                    </FormLabel>
                </FormControl>
            ))}
        </DataForm>
    );
});
