import React from 'react';

import { dictionariesStore } from '../stores/dictionariesStore';
import { observer } from 'mobx-react-lite';
import { FormSimpleSelect } from './common/FormSimpleSelect';

const FellingKindsSelect = observer(({ value, list, ...props }) => {
    const groups = new Map();

    const kindsList = list === undefined ? dictionariesStore.felling_kinds : list;

    kindsList.forEach((kind) => {
        let group = groups.get(kind.form_id);
        if (!group) {
            groups.set(kind.form_id, { id: kind.form_id, name: kind.form_name, items: [] });
            group = groups.get(kind.form_id);
        }
        group.items.push(kind);
    });

    let kindsLabel = undefined;
    if (value) {
        let kind = kindsList.find((kind) => +kind.id === +value);
        if (kind) {
            kindsLabel = kind.form_name;
        }
    }

    return (
        <FormSimpleSelect
            list={Array.from(groups.values())}
            grouped
            label={'Вид рубки' + (kindsLabel ? ': ' + kindsLabel : '')}
            value={value}
            renderTitle={(item) => (item.items ? item.name : `${item.name} (${item.abbr})`)}
            {...props}
        />
    );
});

export { FellingKindsSelect };
