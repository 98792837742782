import React from 'react';

import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';

function FormCheckbox({ loading, name, onChange, value, label, error, helperText, ...props }) {
    let errorText = '';

    if (error && error !== true) {
        errorText = error;
        error = true;
    }

    helperText = errorText + (errorText && helperText ? ', ' : '') + (helperText ? helperText : '');

    return (
        <FormControl error={error} disabled={loading} {...props}>
            <FormControlLabel checked={!!value} onChange={onChange} control={<Checkbox name={name} />} label={label} />
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    );
}

export { FormCheckbox };
