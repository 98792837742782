import React from 'react';

import { Typography, useTheme } from '@mui/material';

function HeaderDivider(props) {
    const theme = useTheme();

    return (
        <Typography
            variant="h4"
            style={{
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(1),
            }}
            {...props}
        />
    );
}

export { HeaderDivider };
