import React from 'react';

import { Typography } from '@mui/material';

function Copyright(props) {
    return (
        <Typography component="div" variant="body2" color="textSecondary" {...props}>
            Производство © {new Date().getFullYear()} ИП Шубин
        </Typography>
    );
}

export { Copyright };
