import React from 'react';

import { FormControl, IconButton, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { HeaderDivider } from '../../../components/HeaderDivider';
import { CoordTypesSelect } from '../../../components/CoordTypesSelect';
import { DEFAULT_GPS_FORMAT, FormGps, FormGpsFormatSwitch } from '../../../components/common/FormGps';
import { SelectFirstOptionAdd } from '../../../components/SelectFirstOptionAdd';
import { Rows } from './CoordsList';

import { useFormStyles } from '../../../hooks/useFormStyles';

function Header({
    coords,
    loading,
    onAdd = () => null,
    inputFormat = DEFAULT_GPS_FORMAT,
    onFormatChange = () => null,
}) {
    const theme = useTheme();

    return (
        <HeaderDivider>
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                }}
            >
                <div style={{ marginRight: theme.spacing(1) }}>Геодезические координаты ({coords.length})</div>
                <div style={{ marginRight: theme.spacing(1) }}>
                    <CoordTypesSelect
                        value=""
                        loading={loading}
                        firstOptionText={<SelectFirstOptionAdd />}
                        SelectProps={{ displayEmpty: true }}
                        size="small"
                        onChange={onAdd}
                    />
                </div>
                <div>
                    <FormGpsFormatSwitch inputFormat={inputFormat} onChange={onFormatChange} />
                </div>
            </div>
        </HeaderDivider>
    );
}

function Fields({ prefix, inputFormat = DEFAULT_GPS_FORMAT, fieldProps, onAdd = () => null, enableAdd = false }) {
    const classes = useFormStyles();
    const latitudeRef = React.useRef(null);
    const longitudeRef = React.useRef(null);
    const autoFocus = fieldProps(prefix + 'autoFocus').value;

    React.useEffect(() => {
        if (latitudeRef.current && autoFocus) {
            latitudeRef.current.focus();
        }
    }, [autoFocus]);

    function handleClick() {
        onAdd({ target: { name: null, value: fieldProps(prefix + 'type').value } });
    }

    function handleLatitudeKeyDown(e) {
        if (e.code === 'Enter' && longitudeRef.current) {
            longitudeRef.current.focus();
        }
    }

    function handleLongitudeKeyDown(e) {
        if (e.code === 'Enter') {
            handleClick();
        }
    }

    return (
        <div className={classes.fieldsGroup}>
            <FormGps
                {...fieldProps(prefix + 'latitude')}
                inputRef={latitudeRef}
                angleFormat="latitude"
                inputFormat={inputFormat}
                label="Широта (X)"
                onKeyDown={handleLatitudeKeyDown}
                required
            />
            <FormGps
                {...fieldProps(prefix + 'longitude')}
                inputRef={longitudeRef}
                angleFormat="longitude"
                inputFormat={inputFormat}
                label="Долгота (Y)"
                onKeyDown={handleLongitudeKeyDown}
                required
            />
            {enableAdd ? (
                <FormControl>
                    <IconButton onClick={handleClick}>
                        <AddIcon />
                    </IconButton>
                </FormControl>
            ) : null}
        </div>
    );
}

function GpsCoordsList({
    coords,
    inputFormat = DEFAULT_GPS_FORMAT,
    fieldPrefix = 'gps.',
    fieldProps,
    loading,
    onAdd = () => null,
    onRemove = () => null,
    onFormatChange = () => null,
}) {
    return (
        <React.Fragment>
            <Header
                coords={coords}
                fieldPrefix={fieldPrefix}
                fieldProps={fieldProps}
                loading={loading}
                onAdd={onAdd}
                inputFormat={inputFormat}
                onFormatChange={onFormatChange}
                onRemove={onRemove}
            />
            <Rows
                coords={coords}
                fieldPrefix={fieldPrefix}
                fieldProps={fieldProps}
                loading={loading}
                onAdd={onAdd}
                onRemove={onRemove}
                inputFormat={inputFormat}
                enableNumeration
                FieldsComponent={Fields}
            />
        </React.Fragment>
    );
}

export { GpsCoordsList };
