import React from 'react';

import { dictionariesStore } from '../stores/dictionariesStore';
import { observer } from 'mobx-react-lite';
import { FormSimpleSelect } from './common/FormSimpleSelect';

const StructuresSelect = observer((props) => {
    const { label = 'Порода' } = props;
    return (
        <FormSimpleSelect
            list={dictionariesStore.structures}
            renderTitle={(item) => `${item.name} (${item.abbr})`}
            label={label}
            {...props}
        />
    );
});

export { StructuresSelect };
