import React from 'react';

import _ from 'lodash';
import moment from 'moment';

import { Button, FormControl, FormLabel, useTheme } from '@mui/material';

import { api } from '../../lib/api';
import { toArrayWithKeys, Validator } from '../../lib/utils';

import { useMountedState } from '../../hooks/useMountedState';
import { messagesStore } from '../../stores/messagesStore';
import { DataForm, useDataForm } from '../../components/common/DataForm';
import { DeclarationStatusesSelect } from '../../components/DeclarationStatusesSelect';
import { FormText } from '../../components/common/FormText';
import { FormDate } from '../../components/common/FormDate';
import { FormNumber } from '../../components/common/FormNumber';
import { HeaderDivider } from '../../components/HeaderDivider';
import { CutoutsAddForm } from './Form/CutoutsAddForm';
import { CutoutsList } from './Form/CutoutsList';

import { useFormStyles } from '../../hooks/useFormStyles';
import { FormFileListUpload } from '../../components/common/FormFilesUpload';

function toBackend(oldData) {
    let data = { ...oldData };

    if (data.cutouts) {
        data.cutouts = data.cutouts.map((cutout) => ({
            id: cutout.id,
            cutout_id: cutout.cutout_id,
            schema: cutout.schema,
            schema_scale: cutout.schema_scale,
        }));
    }

    return data;
}

function DeclarationsForm({ objectId, onClose = () => null, onSave = () => null }) {
    const theme = useTheme();
    const classes = useFormStyles();
    const isMounted = useMountedState();
    const [cutoutAdd, setCutoutAdd] = React.useState(false);

    const {
        data,
        setData,
        errors,
        setErrors,
        loading,
        setLoading,
        setProcessing,
        fieldProps,
        formProps,
        filesUploadProps,
    } = useDataForm({
        initialData: {
            created_on: moment(),
            filed_on: moment(),
            validity_start_on: moment(),
            validity_end_on: moment().add(1, 'year').subtract(1, 'day'),
        },
    });

    function handleOpen() {
        // Создание новой декларации
        if (!objectId) {
            setLoading(true);

            api.declarationsList({
                order: 'number',
                orderDir: 'desc',
                limit: 1,
                filterData: {
                    created_on_from: moment(data.created_on).format('YYYY-01-01'),
                    created_on_to: moment(data.created_on).format('YYYY-12-31'),
                },
            })
                .then((data) => {
                    if (isMounted()) {
                        let nextNumber = data?.rows?.[0]?.number;
                        if (nextNumber) {
                            nextNumber++;
                        } else {
                            nextNumber = 1;
                        }
                        setData((oldData) => ({
                            ...oldData,
                            number: nextNumber,
                        }));
                    }
                })
                .finally(() => {
                    if (isMounted()) {
                        setLoading(false);
                    }
                });
        }

        // Редактирование декларации
        if (objectId) {
            setLoading(true);
            api.declarationsGet(objectId)
                .then((data) => {
                    if (data && isMounted()) {
                        setData(data.data);
                    }
                })
                .finally(() => {
                    if (isMounted()) {
                        setLoading(false);
                    }
                });
        }
    }

    function handleSave() {
        const validator = new Validator(data).checkAll([{ created_on: 'required' }, { number: 'required' }]);

        if (data.status_id) {
            validator.check('status_on', 'required');
            validator.check('filed_on', 'required');
            validator.check('validity_start_on', 'required');
            validator.check('validity_end_on', 'required');
        }

        setErrors(validator.getErrors());

        if (!validator.isHasErrors()) {
            (async () => {
                setProcessing(true);

                try {
                    const sendData = toBackend(data);

                    let result = await (objectId
                        ? api.declarationsEdit(objectId, sendData)
                        : api.declarationsCreate(sendData));

                    if (isMounted()) {
                        if (result) {
                            if (result.errors) {
                                setErrors(result.errors);
                            } else {
                                messagesStore.setSuccessSaved(true);
                                onSave();
                            }
                        }
                    }
                } catch (e) {
                } finally {
                    if (isMounted()) {
                        setProcessing(false);
                    }
                }
            })();
        }
    }

    let cutouts = toArrayWithKeys(data.cutouts);

    function openCutoutAdd() {
        setCutoutAdd(true);
    }

    function closeCutoutAdd() {
        setCutoutAdd(false);
    }

    function handleCutoutAdd(addData, addCutouts) {
        const newData = _.cloneDeep(data);

        addCutouts.forEach((addCutout) => {
            const existingCutout = cutouts.find((v) => v.cutout_id === addCutout.id);
            if (!existingCutout) {
                cutouts.push({
                    keyId: Math.random(),
                    id: null,
                    cutout_id: addCutout.id,
                    schema: addData.schema,
                    schema_scale: addData.schema_scale,
                    cutout: addCutout,
                });
            }
        });

        newData.cutouts = _.cloneDeep(cutouts);

        setData(newData);

        closeCutoutAdd();
    }

    function handleCutoutDelete(index) {
        const newData = _.cloneDeep(data);
        _.pullAt(cutouts, [index]);
        newData.cutouts = _.cloneDeep(cutouts);
        setData(newData);
    }

    return (
        <React.Fragment>
            <DataForm
                {...formProps()}
                fullScreenDialog
                editing={!!objectId}
                onOpen={handleOpen}
                onSave={handleSave}
                onClose={onClose}
            >
                <div className={classes.fieldsGroup}>
                    <div className={classes.fieldsGroup}>
                        <FormDate {...fieldProps('created_on')} label="Дата создания" required />
                        <FormNumber {...fieldProps('number')} label="Номер" required autoFocus />
                    </div>
                    <div className={classes.fieldsGroup}>
                        <FormDate {...fieldProps('filed_on')} label="Дата подачи" />
                    </div>
                </div>
                <div className={classes.fieldsGroup}>
                    <FormDate {...fieldProps('validity_start_on')} label="Действует с" />
                    <FormDate {...fieldProps('validity_end_on')} label="Действует по" />
                </div>
                <div className={classes.fieldsGroup}>
                    <DeclarationStatusesSelect {...fieldProps('status_id')} label="Статус" />
                    <FormDate {...fieldProps('status_on')} label="Дата" />
                    <FormText {...fieldProps('status_comments')} multiline fullWidth label="Комментарии" />
                </div>
                <div className={classes.fieldsGroup}>
                    <FormControl>
                        <FormLabel>Файлы</FormLabel>
                        <FormFileListUpload {...filesUploadProps('files')} />
                    </FormControl>
                </div>
                <HeaderDivider>
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                        }}
                    >
                        <div style={{ marginRight: theme.spacing(1) }}>Лесосеки ({cutouts.length})</div>
                        <div>
                            <Button disabled={loading} variant="outlined" onClick={openCutoutAdd}>
                                Добавить
                            </Button>
                        </div>
                    </div>
                </HeaderDivider>
                <CutoutsList cutouts={cutouts} errors={errors} disabled={loading} onDelete={handleCutoutDelete} />
            </DataForm>
            {cutoutAdd && <CutoutsAddForm currentCutouts={cutouts} onSave={handleCutoutAdd} onClose={closeCutoutAdd} />}
        </React.Fragment>
    );
}

export { DeclarationsForm };
