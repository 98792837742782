import React from 'react';

import { api } from '../../lib/api';

import { useMountedState } from '../../hooks/useMountedState';
import { messagesStore } from '../../stores/messagesStore';
import { FormText } from '../../components/common/FormText';
import { FormCheckbox } from '../../components/common/FormCheckbox';
import { DataForm, useDataForm } from '../../components/common/DataForm';
import { Validator } from '../../lib/utils';
import { ForestriesSelect } from '../../components/ForestriesSelect';
import { FormNumber } from '../../components/common/FormNumber';

import { useFormStyles } from '../../hooks/useFormStyles';

function ForestriesForm({ objectId, onClose = () => null, onSave = () => null }) {
    const classes = useFormStyles();
    const isMounted = useMountedState();

    const { data, setData, setErrors, setLoading, setProcessing, fieldProps, formProps } = useDataForm();

    function handleOpen() {
        if (objectId) {
            setLoading(true);
            api.forestriesGet(objectId)
                .then((data) => {
                    if (data && isMounted()) {
                        setData(data.data);
                    }
                })
                .finally(() => {
                    if (isMounted()) {
                        setLoading(false);
                    }
                });
        }
    }

    function handleSave() {
        const validator = new Validator(data).checkAll([{ name: 'required' }]);

        if (data.is_district) {
            validator.checkAll([{ forestry_id: 'required' }, { quarters: 'required' }]);
        }

        setErrors(validator.getErrors());

        if (!validator.isHasErrors()) {
            setProcessing(true);

            (objectId ? api.forestriesEdit(objectId, data) : api.forestriesCreate(data))
                .then((result) => {
                    if (isMounted()) {
                        if (result) {
                            if (result.errors) {
                                setErrors(result.errors);
                            } else {
                                messagesStore.setSuccessSaved(true);
                                onSave();
                            }
                        }
                    }
                })
                .finally(() => {
                    if (isMounted()) {
                        setProcessing(false);
                    }
                });
        }
    }

    return (
        <DataForm {...formProps()} editing={!!objectId} onOpen={handleOpen} onSave={handleSave} onClose={onClose}>
            <div className={classes.fieldsGroup}>
                <FormText {...fieldProps('name')} label="Название" required autoFocus />
                <FormCheckbox {...fieldProps('is_district')} label="Участковое лесничество" />
            </div>
            {data.is_district ? (
                <div className={classes.fieldsGroup}>
                    <ForestriesSelect
                        {...fieldProps('forestry_id')}
                        superiorOnly
                        excludeIds={[objectId]}
                        required
                        label="Вышестоящее лесничество"
                    />
                    <FormNumber {...fieldProps('quarters')} required label="Кол-во кварталов" />
                </div>
            ) : null}
            <FormText {...fieldProps('responsible')} label="Ответственный лесничий" fullWidth />
            <FormText {...fieldProps('municipal')} label="Муниципальный район" fullWidth />
        </DataForm>
    );
}

export { ForestriesForm };
