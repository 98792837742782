import React from 'react';

import _ from 'lodash';
import { useNavigate, useLocation } from 'react-router';

import { makeStyles } from '@mui/styles';
import { Typography, Button, CircularProgress } from '@mui/material';

import { authStore } from '../../stores/authStore';
import { api } from '../../lib/api';
import { useDataForm } from '../../components/common/DataForm';
import { FormText } from '../../components/common/FormText';
import { DEFAULT_ROUTE } from '../../constants/routes';

const useStyles = makeStyles((theme) => ({
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function Login() {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();

    const initialData = { login: localStorage.getItem('login:login') || '' };

    const [mainError, setMainError] = React.useState(false);

    const { data, processing, setProcessing, setErrors, isHasErrors, fieldProps } = useDataForm({
        initialData,
        handleChangeMiddleware,
    });

    function handleChangeMiddleware({ name, value }) {
        if ('login' === name) {
            localStorage.setItem('login:' + name, value);
        }

        return { newErrors: {} };
    }

    function handleLogin() {
        setMainError(false);
        setProcessing(true);
        api.login(data.login, data.password).then((r) => {
            setProcessing(false);
            if (r) {
                if (r.errors) {
                    setErrors(r.errors);
                } else {
                    authStore.setAuthorized(r);
                    if (location.pathname === '/') {
                        navigate(DEFAULT_ROUTE);
                    }
                }
            } else {
                setMainError('Что-то пошло не так, попробуйте позже');
            }
        });
    }

    const isBtnDisabled = _.isEmpty(data.login) || _.isEmpty(data.password) || isHasErrors || processing;

    return (
        <form onSubmit={(e) => e.preventDefault()}>
            <FormText
                {...fieldProps('login')}
                fullWidth
                label="Ваш логин или e-mail"
                required
                autoComplete="username"
                autoFocus
            />
            <FormText
                {...fieldProps('password')}
                fullWidth
                label="Ваш пароль"
                required
                autoComplete="current-password"
                type="password"
            />
            {mainError && <Typography color="error">{mainError}</Typography>}
            <Button
                type="submit"
                fullWidth
                className={classes.submit}
                onClick={handleLogin}
                disabled={isBtnDisabled}
                startIcon={processing && <CircularProgress size={20} />}
            >
                Войти в систему
            </Button>
        </form>
    );
}

export { Login };
