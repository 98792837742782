import React from 'react';

import { ListSubheader, MenuItem } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { dictionariesStore } from '../stores/dictionariesStore';
import { FormText } from './common/FormText';

const ForestriesSelect = observer(
    ({ loading, superiorOnly, excludeIds, fullWidth = true, label = 'Участковое лесничество', ...props }) => {
        let forestries = dictionariesStore.forestries;

        if (Array.isArray(excludeIds) && excludeIds.length) {
            forestries = forestries.filter((f) => excludeIds.indexOf(f.id) < 0);
        }

        let forestryOptions = [];

        if (superiorOnly) {
            forestryOptions = forestries.filter((f) => null === f.forestry_id);
            forestryOptions = forestryOptions.map((superiorForestry) => (
                <MenuItem key={superiorForestry.id} value={superiorForestry.id}>
                    {superiorForestry.name}
                </MenuItem>
            ));
        } else {
            forestryOptions = [];

            forestries.forEach((forestry) => {
                if (null === forestry.forestry_id) {
                    let childs = [];

                    forestries.forEach((child) => {
                        if (child.forestry_id === forestry.id) {
                            childs.push(
                                <MenuItem key={child.id} value={child.id}>
                                    {child.name}
                                </MenuItem>
                            );
                        }
                    });

                    if (childs.length > 0) {
                        forestryOptions.push(
                            <ListSubheader disableSticky inset key={forestry.id}>
                                {forestry.name}
                            </ListSubheader>
                        );

                        forestryOptions = [...forestryOptions, ...childs];
                    }
                }
            });
        }

        return (
            <FormText select fullWidth={fullWidth} loading={loading} label={label} {...props}>
                {forestryOptions.length === 0 ? (
                    <MenuItem disabled value="">
                        Нет вариантов
                    </MenuItem>
                ) : (
                    <MenuItem value="">Не выбрано</MenuItem>
                )}
                {forestryOptions}
            </FormText>
        );
    }
);

export { ForestriesSelect };
