import React from 'react';

import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import _ from 'lodash';

import { api } from '../../lib/api';

import { useMountedState } from '../../hooks/useMountedState';
import { messagesStore } from '../../stores/messagesStore';
import { ForestriesSelect } from '../../components/ForestriesSelect';
import { DataForm, useDataForm } from '../../components/common/DataForm';
import { humanNumber, toArrayWithKeys, Validator } from '../../lib/utils';
import { AllotmentCategoriesRadio } from '../../components/AllotmentCategoriesRadio';
import { ForestTypesSelect } from '../../components/ForestTypesSelect';
import { StructuresSelect } from '../../components/StructuresSelect';
import { FarmsSelect } from '../../components/FarmsSelect';
import { FormNumber } from '../../components/common/FormNumber';
import { FormText } from '../../components/common/FormText';
import { HeaderDivider } from '../../components/HeaderDivider';
import { StructuresList } from './Form/StructuresList';
import { FellingKindsSelect } from '../../components/FellingKindsSelect';
import { dictionariesStore } from '../../stores/dictionariesStore';
import { ALLOTMENT_CATEGORY_PROTECTED, FELLING_FORM_SELECTION_ID } from '../../constants/dictionaries';

import { useFormStyles } from '../../hooks/useFormStyles';

const AllotmentsForm = observer(({ objectId, onClose = () => null, onSave = () => null }) => {
    const classes = useFormStyles();

    const isMounted = useMountedState();

    const { data, setData, setErrors, loading, setLoading, setProcessing, fieldProps, formProps } = useDataForm();

    const fellingKinds = dictionariesStore.felling_kinds;
    const fellingKind = fellingKinds.find((kind) => +kind.id === +data?.felling_kind_id);

    let selectionVolume = (data?.selection_percent / 100) * data?.reserve_wet;
    if (isNaN(selectionVolume)) {
        selectionVolume = null;
    }

    function handleOpen() {
        if (objectId) {
            setLoading(true);
            api.allotmentsGet(objectId)
                .then((data) => {
                    if (data && isMounted()) {
                        setData(data.data);
                    }
                })
                .finally(() => {
                    if (isMounted()) {
                        setLoading(false);
                    }
                });
        }
    }

    let structures = toArrayWithKeys(data.structures);

    function handleAddStructure(e) {
        const structure_id = e.target.value;
        const newData = _.cloneDeep(data);
        structures.push({ structure_id, keyId: Math.random() });
        newData.structures = _.cloneDeep(structures);
        setData(newData);
    }

    function handleRemoveStructure(index) {
        const newData = _.cloneDeep(data);
        _.pullAt(structures, [index]);
        newData.structures = _.cloneDeep(structures);
        setData(newData);
    }

    function handleSave() {
        const validator = new Validator(data).checkAll([
            { number: 'required' },
            { quarter: 'required' },
            { square: 'required' },
            { forestry_id: 'required' },
            { category_id: 'required' },
        ]);

        setErrors(validator.getErrors());

        if (!validator.isHasErrors()) {
            setProcessing(true);

            (objectId ? api.allotmentsEdit(objectId, data) : api.allotmentsCreate(data))
                .then((result) => {
                    if (isMounted()) {
                        if (result) {
                            if (result.errors) {
                                setErrors(result.errors);
                            } else {
                                messagesStore.setSuccessSaved(true);
                                onSave();
                            }
                        }
                    }
                })
                .finally(() => {
                    if (isMounted()) {
                        setProcessing(false);
                    }
                });
        }
    }

    return (
        <DataForm
            {...formProps()}
            editing={!!objectId}
            onOpen={handleOpen}
            onSave={handleSave}
            onClose={onClose}
            fullScreenDialog
        >
            <div className={classes.fieldsGroup}>
                <FormNumber {...fieldProps('quarter')} label="Квартал" required autoFocus />
                <FormNumber {...fieldProps('number')} label="Выдел" required />
            </div>
            <div className={classes.fieldsGroup}>
                <ForestriesSelect {...fieldProps('forestry_id')} required />
            </div>
            <div className={classes.fieldsGroup}>
                <div className={classes.fieldsGroup}>
                    <FarmsSelect {...fieldProps('farm_id')} />
                </div>
                <div className={classes.fieldsGroup}>
                    <FellingKindsSelect {...fieldProps('felling_kind_id')} />
                    {+fellingKind?.form_id === FELLING_FORM_SELECTION_ID ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <FormNumber {...fieldProps('selection_percent')} label="Процент выборки" />
                            {selectionVolume ? (
                                <Box ml={1} style={{ whiteSpace: 'nowrap' }}>
                                    {humanNumber(selectionVolume) + ' м³'}
                                </Box>
                            ) : null}
                        </div>
                    ) : null}
                </div>
            </div>
            <div>
                <AllotmentCategoriesRadio {...fieldProps('category_id')} required />
                {+data.category_id === ALLOTMENT_CATEGORY_PROTECTED ? (
                    <FormText multiline fullWidth {...fieldProps('category_comments')} label="Категория защитности" />
                ) : null}
            </div>
            <div className={classes.fieldsGroup}>
                <FormNumber {...fieldProps('square')} label="Площадь (га)" />
            </div>
            <HeaderDivider>Cостав леса</HeaderDivider>
            <div className={classes.fieldsGroup}>
                <div className={classes.fieldsGroup}>
                    <FormText {...fieldProps('structure_code')} label="Состав (код)" />
                </div>
                <div className={classes.fieldsGroup}>
                    <FormNumber
                        {...fieldProps('reserve_wet_ha')}
                        label="Корневой запас, м³"
                        helperText="На 1 га"
                        className={classes.wideNumber}
                    />
                    <FormNumber
                        {...fieldProps('reserve_wet')}
                        label="Корневой запас, м³"
                        helperText="Общий на выдел"
                        className={classes.wideNumber}
                    />
                </div>
            </div>
            <div className={classes.fieldsGroup}>
                <FormText {...fieldProps('description')} multiline fullWidth label="Описание" />
            </div>
            <div className={classes.fieldsGroup}>
                <div className={classes.fieldsGroup}>
                    <FormNumber {...fieldProps('tier')} label="Ярус" />
                    <FormNumber {...fieldProps('tier_height')} label="Высота яруса" />
                </div>
                <div className={classes.fieldsGroup}>
                    <FormNumber {...fieldProps('age')} label="Возраст" />
                    <FormNumber {...fieldProps('age_class')} label="Класс возраста" />
                    <FormNumber {...fieldProps('age_group')} label="Группа возраста" />
                </div>
                <div className={classes.fieldsGroup}>
                    <FormText {...fieldProps('bonitet')} label="Бонитет" />
                </div>
                <div className={classes.fieldsGroup}>
                    <FormNumber {...fieldProps('fullness')} label="Полнота" />
                </div>
            </div>
            <div className={classes.fieldsGroup}>
                <ForestTypesSelect {...fieldProps('forest_types')} />
            </div>
            <StructuresList
                {...{
                    structures,
                    fieldProps,
                    loading,
                    onAdd: handleAddStructure,
                    onRemove: handleRemoveStructure,
                }}
            />
            <div className={classes.fieldsGroup}>
                <FormText {...fieldProps('activities')} label="Хозяйственные мероприятия" fullWidth />
            </div>
            <HeaderDivider>Подрост</HeaderDivider>
            <div className={classes.fieldsGroup}>
                <div className={classes.fieldsGroup}>
                    <FormNumber {...fieldProps('undergrowth_count')} label="Количество" />
                    <FormNumber {...fieldProps('undergrowth_height')} label="Высота" />
                </div>
                <div className={classes.fieldsGroup}>
                    <FormNumber {...fieldProps('undergrowth_age')} label="Возраст" />
                    <StructuresSelect {...fieldProps('undergrowth_structure_id')} />
                </div>
            </div>
        </DataForm>
    );
});

export { AllotmentsForm };
