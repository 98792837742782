import React from 'react';

import { makeStyles } from '@mui/styles';
import { Dialog, Link, Paper } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    schemaPreview: {
        display: 'inline-block',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function PhotoWithPreview({ url = null, width = 40, height = 40 }) {
    const classes = useStyles();

    const [showPreview, setShowPreview] = React.useState(false);

    function handleShow() {
        if (url) {
            setShowPreview(true);
        }
    }

    const paperStyle = {
        width: width + 'px',
        height: height + 'px',
    };

    const previewUrl = encodeURI(url && url.match(/^\w+:/) ? url : process.env.PUBLIC_URL + '/' + url);

    if (previewUrl) {
        paperStyle.backgroundImage = `url(${previewUrl})`;
    }

    if (url) {
        paperStyle.cursor = 'pointer';
    }

    return (
        <div>
            <Paper className={classes.schemaPreview} style={paperStyle} onClick={handleShow}></Paper>
            <Dialog
                open={showPreview}
                onClose={() => setShowPreview(false)}
                maxWidth="xl"
                PaperProps={{
                    style: {
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                }}
            >
                <Link download href={previewUrl} style={{ maxWidth: '80vw', maxHeight: '80vh' }}>
                    <img src={previewUrl} style={{ maxWidth: '800vw', maxHeight: '80vh' }} alt="" />
                </Link>
            </Dialog>
        </div>
    );
}

export { PhotoWithPreview };
