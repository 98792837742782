import React from 'react';

import _ from 'lodash';

import { makeStyles } from '@mui/styles';
import { Alert, Typography, Button, CircularProgress } from '@mui/material';

import { api } from '../../lib/api';
import { useDataForm } from '../../components/common/DataForm';
import { FormText } from '../../components/common/FormText';

const useStyles = makeStyles((theme) => ({
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function Reminder() {
    const classes = useStyles();
    const initialData = { login: localStorage.getItem('login:login') || '' };
    const [mainError, setMainError] = React.useState(false);

    const handleChangeMiddleware = ({ name, value, newErrors }) => {
        if (name === 'password' || name === 'passwordConfirmation') {
            delete newErrors['password'];
            delete newErrors['passwordConfirmation'];
        } else {
            localStorage.setItem('login:' + name, value);
        }

        return { newErrors };
    };

    const { data, setData, processing, setProcessing, setErrors, isHasErrors, fieldProps } = useDataForm({
        initialData,
        handleChangeMiddleware,
    });

    function mergeData(data) {
        setData((oldData) => ({ ...oldData, ...data }));
    }

    function handlePasswordReset() {
        const somethingWrong = 'Что-то пошло не так, попробуйте позже';

        if (!data.isCodeSent) {
            setMainError(false);
            setProcessing(true);
            api.passwordReset(data.login)
                .then((r) => {
                    if (r) {
                        if (r.codeSent) {
                            mergeData({ isCodeSent: true });
                        } else if (r.errors) {
                            setErrors(r.errors);
                        }
                    } else {
                        setMainError(somethingWrong);
                    }
                })
                .finally(() => setProcessing(false));
        } else if (!data.isPasswordChanged) {
            let newErrors = {};

            if (data.password !== data.passwordConfirmation) {
                let err = 'Пароли не совпадают';
                newErrors = {
                    ...newErrors,
                    password: err,
                    passwordConfirmation: err,
                };
            }

            let err = 'Пароль должен быть не менее 8-ми символов';

            if (data.password.length < 8) {
                newErrors = {
                    ...newErrors,
                    password: err,
                };
            }

            if (data.password.length < 8) {
                newErrors = {
                    ...newErrors,
                    passwordConfirmation: err,
                };
            }

            if (Object.keys(newErrors).length > 0) {
                setErrors(newErrors);
            } else {
                setMainError(false);
                setProcessing(true);
                api.passwordReset(data.login, data.code, data.password)
                    .then((r) => {
                        if (r) {
                            if (r.passwordChanged) {
                                mergeData({ isPasswordChanged: true });
                            } else if (r.errors) {
                                setErrors(r.errors);
                            }
                        } else {
                            setMainError(somethingWrong);
                        }
                    })
                    .finally(() => setProcessing(false));
            }
        }
    }

    let isBtnDisabled = false;

    if (!data.isCodeSent) {
        isBtnDisabled = _.isEmpty(data.login) || isHasErrors;
    } else {
        isBtnDisabled =
            _.isEmpty(data.code) || _.isEmpty(data.password) || _.isEmpty(data.passwordConfirmation) || isHasErrors;
    }

    if (processing) {
        isBtnDisabled = true;
    }

    if (data.isPasswordChanged) {
        return <Alert severity="success">Пароль успешно изменён, теперь вы можете войти в систему.</Alert>;
    }

    return (
        <form onSubmit={(e) => e.preventDefault()}>
            <FormText
                {...fieldProps('login')}
                fullWidth
                label="Введите ваш логин или e-mail"
                required
                autoFocus
                autoComplete="username"
                disabled={data.isCodeSent}
            />
            {data.isCodeSent && (
                <React.Fragment>
                    <FormText
                        {...fieldProps('code')}
                        fullWidth
                        label="Код, полученный по e-mail"
                        required
                        autoFocus
                        autoComplete="off"
                    />
                    <FormText
                        {...fieldProps('password')}
                        fullWidth
                        label="Новый пароль"
                        required
                        type="password"
                        autoComplete="new-password"
                    />
                    <FormText
                        {...fieldProps('passwordConfirmation')}
                        fullWidth
                        label="Новый пароль ещё раз"
                        required
                        type="password"
                        autoComplete="new-password"
                    />
                </React.Fragment>
            )}
            {mainError && <Typography color="error">{mainError}</Typography>}
            <Button
                type="submit"
                fullWidth
                className={classes.submit}
                onClick={handlePasswordReset}
                disabled={isBtnDisabled}
                startIcon={processing && <CircularProgress size={20} />}
            >
                {data.isCodeSent ? 'Сменить пароль' : 'Получить код'}
            </Button>
        </form>
    );
}

export { Reminder };
