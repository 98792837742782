import { makeAutoObservable } from 'mobx';

class MessagesStore {
    constructor() {
        this.isError500 = false;
        this.isSuccessSaved = false;
        makeAutoObservable(this);
    }

    setError500(isError) {
        this.isError500 = isError;
    }

    setSuccessSaved(isError) {
        this.isSuccessSaved = isError;
    }
}

const messagesStore = new MessagesStore();

export { messagesStore };
