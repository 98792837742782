import React from 'react';

import { DataTable, useDataTable } from './common/DataTable';

export function CustomDataTable({ ...props }) {
    return (
        <DataTable
            TableContainerProps={{ style: { height: 'calc(100vh - 224px)' } }}
            rowsPerPageOptions={[20, 50, 100]}
            {...props}
        />
    );
}

export function useCustomDataTable(customConfig = {}) {
    return useDataTable({ rowsPerPage: 50, ...customConfig });
}
