import React from 'react';

import { dictionariesStore } from '../stores/dictionariesStore';
import { observer } from 'mobx-react-lite';

import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material';

const AllotmentCategoriesRadio = observer(
    ({ label, loading, required, error, helperText, fullWidth, value: valueProp, ...componentProps }) => {
        let errorText = '';

        if (error && error !== true) {
            errorText = error;
            error = true;
        }

        helperText = errorText + (errorText && helperText ? ', ' : '') + (helperText ? helperText : '');

        valueProp = valueProp + '';

        return (
            <FormControl fullWidth={fullWidth} component="fieldset">
                <FormLabel component="legend" required={required} error={error} disabled={loading}>
                    {label ? label : 'Категория'}
                </FormLabel>
                <RadioGroup value={valueProp} {...componentProps}>
                    {dictionariesStore.allotment_categories.map((item) => (
                        <FormControlLabel
                            key={item.id}
                            value={item.id + ''}
                            control={<Radio />}
                            label={item.name}
                            disabled={loading}
                        />
                    ))}
                </RadioGroup>
                <FormHelperText error={error} disabled={loading}>
                    {helperText}
                </FormHelperText>
            </FormControl>
        );
    }
);

export { AllotmentCategoriesRadio };
