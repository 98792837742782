import React from 'react';

import { makeStyles } from '@mui/styles';
import { Button, Dialog, FormControl, FormHelperText, FormLabel, IconButton, Link, Paper } from '@mui/material';

import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles((theme) => ({
    schemaPreview: {
        display: 'inline-block',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    buttonsContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        '& > *': {
            marginRight: theme.spacing(1),
        },
    },
}));

function FormOneImageUpload({
    width = 250,
    height = 250,
    buttonText,
    label,
    name,
    value,
    onChange = () => null,
    loading,
    error,
    required,
    helperText,
}) {
    const classes = useStyles();

    const [showPreview, setShowPreview] = React.useState(false);

    let errorText = '';

    if (error && error !== true) {
        errorText = error;
        error = true;
    }

    helperText = errorText + (errorText && helperText ? ', ' : '') + (helperText ? helperText : '');

    function triggerOnChange(value) {
        onChange({
            target: {
                type: 'file',
                name,
                value,
            },
        });
    }

    function handleChange(e) {
        if (e.target.files.length) {
            let preview = e.target.files[0];
            let fr = new FileReader();
            fr.onload = (e) => {
                triggerOnChange({
                    name: preview.name,
                    size: preview.size,
                    type: preview.type,
                    data: e.target.result,
                });
            };
            fr.onerror = (e) => triggerOnChange(null);
            fr.readAsDataURL(preview);
        }
    }

    function handleShow() {
        setShowPreview(true);
    }

    const paperStyle = {
        width: width + 'px',
        height: height + 'px',
        cursor: 'pointer',
    };

    const previewUrl = value ? encodeURI(value?.data ? value.data : '/' + value) : null;

    if (previewUrl) {
        paperStyle.backgroundImage = `url(${previewUrl})`;
    }

    return (
        <div>
            <FormControl>
                <FormLabel required={required} error={error} disabled={loading}>
                    {label}
                </FormLabel>
                {previewUrl ? (
                    <Paper className={classes.schemaPreview} style={paperStyle} onClick={handleShow}></Paper>
                ) : null}
                <div className={classes.buttonsContainer}>
                    <label style={{ display: 'block' }}>
                        {!loading ? (
                            <input
                                accept="image/*"
                                style={{ display: 'none' }}
                                type="file"
                                value=""
                                onChange={handleChange}
                            />
                        ) : null}
                        <Button
                            size="small"
                            variant="outlined"
                            disabled={loading}
                            component="span"
                            startIcon={<PhotoCameraIcon />}
                        >
                            {buttonText ? buttonText : 'Прикрепить'}
                        </Button>
                    </label>
                    {value ? (
                        <IconButton size="small" disabled={loading} onClick={() => triggerOnChange(null)}>
                            <DeleteIcon />
                        </IconButton>
                    ) : null}
                </div>
                <FormHelperText error={error} disabled={loading}>
                    {helperText}
                </FormHelperText>
            </FormControl>
            <Dialog
                open={showPreview}
                onClose={() => setShowPreview(false)}
                maxWidth="xl"
                PaperProps={{
                    style: {
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                }}
            >
                <Link download href={previewUrl} style={{ maxWidth: '80vw', maxHeight: '80vh' }}>
                    <img src={previewUrl} style={{ maxWidth: '80vw', maxHeight: '80vh' }} alt="" />
                </Link>
            </Dialog>
        </div>
    );
}

export { FormOneImageUpload };
