import React from 'react';

import { api } from '../../lib/api';

import { useMountedState } from '../../hooks/useMountedState';
import { messagesStore } from '../../stores/messagesStore';
import { FormText } from '../../components/common/FormText';
import { DataForm, useDataForm } from '../../components/common/DataForm';
import { Validator } from '../../lib/utils';
import { FormCheckbox } from '../../components/common/FormCheckbox';
import { useFormStyles } from '../../hooks/useFormStyles';

function AdminAccountsForm({ objectId, onClose = () => null, onSave = () => null }) {
    const classes = useFormStyles();
    const isMounted = useMountedState();

    const { data, setData, setErrors, setLoading, setProcessing, fieldProps, formProps } = useDataForm();

    function handleOpen() {
        if (objectId) {
            setLoading(true);
            api.adminAccountsGet(objectId)
                .then((data) => {
                    if (data && isMounted()) {
                        setData(data.data);
                    }
                })
                .finally(() => {
                    if (isMounted()) {
                        setLoading(false);
                    }
                });
        }
    }

    function handleSave() {
        const validator = new Validator(data).checkAll([
            { name: 'required' },
            { login: 'required' },
            { email: 'required' },
        ]);
        setErrors(validator.getErrors());

        if (!validator.isHasErrors()) {
            setProcessing(true);

            (objectId ? api.adminAccountsEdit(objectId, data) : api.adminAccountsCreate(data))
                .then((result) => {
                    if (isMounted()) {
                        if (result) {
                            if (result.errors) {
                                setErrors(result.errors);
                            } else {
                                messagesStore.setSuccessSaved(true);
                                onSave();
                            }
                        }
                    }
                })
                .finally(() => {
                    if (isMounted()) {
                        setProcessing(false);
                    }
                });
        }
    }

    return (
        <DataForm {...formProps()} editing={!!objectId} onOpen={handleOpen} onSave={handleSave} onClose={onClose}>
            <div className={classes.fieldsGroup}>
                <FormText {...fieldProps('name')} label="Имя" autoFocus required />
                <FormText {...fieldProps('login')} label="Логин" required autoComplete="login" />
                <FormText {...fieldProps('email')} label="E-mail" required type="email" />
                <FormCheckbox {...fieldProps('is_disabled')} label="Заблокирован" />
            </div>
        </DataForm>
    );
}

export { AdminAccountsForm };
