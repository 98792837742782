import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

function ConfirmDialog({ onConfirm, onClose, title, text, open }) {
    const handleClose = () => {
        onClose();
    };

    const handleConfirm = () => {
        onConfirm();
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            {title && <DialogTitle>{title}</DialogTitle>}
            <DialogContent>{text && <DialogContentText>{text}</DialogContentText>}</DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm}>Подтвердить</Button>
                <Button onClick={handleClose} variant="text" autoFocus>
                    Отмена
                </Button>
            </DialogActions>
        </Dialog>
    );
}
export { ConfirmDialog };
