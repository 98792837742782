import React from 'react';

import { FormText } from './FormText';
import { ListSubheader, MenuItem } from '@mui/material';

function FormSimpleSelect({
    list = [],
    grouped = false,
    firstOptionText,
    disableFirstOption = false,
    renderTitle = (item) => item.name,
    ...props
}) {
    const menuItems = [];

    if (grouped) {
        list.forEach((group) => {
            menuItems.push(<ListSubheader key={group.id}>{renderTitle(group)}</ListSubheader>);
            group.items.forEach((item) =>
                menuItems.push(
                    <MenuItem key={group.id + ':' + item.id} value={item.id}>
                        {renderTitle(item)}
                    </MenuItem>
                )
            );
        });
    } else {
        list.forEach((item) =>
            menuItems.push(
                <MenuItem key={item.id} value={item.id}>
                    {renderTitle(item)}
                </MenuItem>
            )
        );
    }

    return (
        <FormText select style={{ minWidth: 200, maxWidth: 'calc(100vw - 50px)' }} {...props}>
            {list.length === 0 ? (
                <MenuItem disabled value="">
                    Нет вариантов
                </MenuItem>
            ) : !disableFirstOption ? (
                <MenuItem value="">{firstOptionText ? firstOptionText : 'Не выбрано'}</MenuItem>
            ) : null}
            {menuItems}
        </FormText>
    );
}

export { FormSimpleSelect };
