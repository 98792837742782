import React from 'react';

import _ from 'lodash';

import { FormControl, IconButton, Table, TableBody, TableCell, TableRow, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { FormNumber } from '../../../components/common/FormNumber';
import { HeaderDivider } from '../../../components/HeaderDivider';
import { CoordTypesSelect } from '../../../components/CoordTypesSelect';
import { SelectFirstOptionAdd } from '../../../components/SelectFirstOptionAdd';
import { DeleteButtonWithConfirmation } from '../../../components/DeleteButtonWithConfirmation';
import { FormText } from '../../../components/common/FormText';

import { useFormStyles } from '../../../hooks/useFormStyles';

function Header({ coords, loading, onAdd }) {
    const theme = useTheme();

    return (
        <HeaderDivider>
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                }}
            >
                <div style={{ marginRight: theme.spacing(1) }}>Координаты – Азимут/Румб ({coords.length})</div>
                <div>
                    <CoordTypesSelect
                        value=""
                        loading={loading}
                        firstOptionText={<SelectFirstOptionAdd />}
                        SelectProps={{ displayEmpty: true }}
                        size="small"
                        onChange={onAdd}
                    />
                </div>
            </div>
        </HeaderDivider>
    );
}

function Fields({ prefix, fieldProps, onAdd = () => null, enableAdd = false }) {
    const classes = useFormStyles();
    const numberRef = React.useRef(null);
    const rumbaRef = React.useRef(null);
    const lengthRef = React.useRef(null);
    const autoFocus = fieldProps(prefix + 'autoFocus').value;

    React.useEffect(() => {
        if (numberRef.current && autoFocus) {
            numberRef.current.focus();
        }
    }, [autoFocus]);

    function handleClick() {
        onAdd({ target: { name: null, value: fieldProps(prefix + 'type').value } });
    }

    function handleNumberKeyDown(e) {
        if (e.code === 'Enter' && rumbaRef.current) {
            rumbaRef.current.focus();
        }
    }

    function handleRumbaKeyDown(e) {
        if (e.code === 'Enter' && lengthRef.current) {
            lengthRef.current.focus();
        }
    }

    function handleLengthKeyDown(e) {
        if (e.code === 'Enter' && rumbaRef.current) {
            handleClick();
        }
    }

    return (
        <div className={classes.fieldsGroup}>
            <FormText
                {...fieldProps(prefix + 'number')}
                inputRef={numberRef}
                label="Номер точки"
                style={{ width: 140 }}
                onKeyDown={handleNumberKeyDown}
                required
            />
            <FormText
                {...fieldProps(prefix + 'rumba')}
                inputRef={rumbaRef}
                label="Румбы линий"
                onKeyDown={handleRumbaKeyDown}
                required
            />
            <FormNumber
                {...fieldProps(prefix + 'length')}
                inputRef={lengthRef}
                label="Длина линий, м"
                onKeyDown={handleLengthKeyDown}
                required
            />
            {enableAdd ? (
                <FormControl>
                    <IconButton onClick={handleClick}>
                        <AddIcon />
                    </IconButton>
                </FormControl>
            ) : null}
        </div>
    );
}

export function Rows({
    coords,
    loading,
    fieldPrefix,
    fieldProps,
    onAdd = () => null,
    onRemove = () => null,
    enableNumeration = false,
    FieldsComponent = null,
    ...props
}) {
    if (!coords.length) {
        return null;
    }

    const groups = _.groupBy(
        coords.map((coord, index) => ({ ...coord, fieldIndex: index })),
        (coord) => coord.type
    );

    const types = Object.keys(groups).sort((a, b) => +a - +b);

    let rows = [];
    let rowNumber = 1;

    for (let type of types) {
        const prefixedRow = (
            <TableRow>
                <TableCell colSpan={enableNumeration ? 3 : 2}>
                    {+type === 1 ? 'Привязка' : null}
                    {+type === 2 ? 'Лесосека' : null}
                </TableCell>
            </TableRow>
        );

        for (let index in groups[type]) {
            index = parseInt(index);
            const coord = groups[type][index];
            const prefix = fieldPrefix + coord.fieldIndex + '.';
            rows.push(
                <React.Fragment key={coord.keyId}>
                    {index === 0 ? prefixedRow : null}
                    <TableRow>
                        {enableNumeration ? (
                            <TableCell style={{ whiteSpace: 'nowrap' }}>{rowNumber++}</TableCell>
                        ) : null}
                        <TableCell>
                            <FieldsComponent
                                prefix={prefix}
                                fieldProps={fieldProps}
                                loading={loading}
                                onAdd={onAdd}
                                enableAdd={index === groups[type].length - 1}
                                {...props}
                            />
                        </TableCell>
                        <TableCell align="center">
                            <DeleteButtonWithConfirmation
                                data={coord.fieldIndex}
                                disabled={loading}
                                onDelete={onRemove}
                            />
                        </TableCell>
                    </TableRow>
                </React.Fragment>
            );
        }
    }

    return (
        <Table>
            <TableBody>{rows}</TableBody>
        </Table>
    );
}

function CoordsList({ coords, fieldPrefix = 'coords.', fieldProps, loading, onAdd, onRemove }) {
    return (
        <React.Fragment>
            <Header
                coords={coords}
                fieldPrefix={fieldPrefix}
                fieldProps={fieldProps}
                loading={loading}
                onAdd={onAdd}
                onRemove={onRemove}
            />
            <Rows
                coords={coords}
                fieldPrefix={fieldPrefix}
                fieldProps={fieldProps}
                loading={loading}
                onAdd={onAdd}
                onRemove={onRemove}
                FieldsComponent={Fields}
            />
        </React.Fragment>
    );
}

export { CoordsList };
