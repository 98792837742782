import { useEffect, useRef } from 'react';

export function useMountedState() {
    const mountedRef = useRef(false);

    useEffect(() => {
        mountedRef.current = true;
        return () => (mountedRef.current = false);
    }, []);

    return () => mountedRef.current;
}

export function useIsFirstRender() {
    const ref = useRef(true);
    useEffect(() => {
        ref.current = false;
    }, []);
    return ref.current;
}
