import React from 'react';

import moment from 'moment';

import { DatePicker } from '@mui/lab';
import { TextField } from '@mui/material';

export function FormDate({ loading, error, helperText, name, value, onChange = () => null, ...props }) {
    let errorText = '';

    if (error && error !== true) {
        errorText = error;
        error = true;
    }

    helperText = errorText + (errorText && helperText ? ', ' : '') + (helperText ? helperText : '');

    function handleChange(value) {
        onChange({
            target: {
                type: 'text',
                name,
                value: value ? value.format('YYYY-MM-DD') : value,
            },
        });
    }

    let dateValue = value ? moment(value) : null;

    return (
        <DatePicker
            inputFormat="DD.MM.yyyy"
            mask="__.__.____"
            cancelText="Отмена"
            clearText="Очистить"
            okText="Выбрать"
            todayText="Сегодня"
            showDaysOutsideCurrentMonth
            clearable
            onChange={handleChange}
            value={dateValue}
            {...props}
            renderInput={(renderProps) => {
                if (error) {
                    renderProps.error = error;
                }
                return <TextField name={name} disabled={loading} helperText={helperText} {...renderProps} />;
            }}
        />
    );
}
