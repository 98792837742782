import React from 'react';

import _ from 'lodash';

import {
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    useMediaQuery,
    useTheme,
    FormHelperText,
    LinearProgress,
    IconButton,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

import CancelIcon from '@mui/icons-material/Cancel';

import { api } from '../../lib/api';

function useDataForm({ initialData = {}, handleChange: customHandleChange, handleChangeMiddleware } = {}) {
    const [data, setData] = React.useState(initialData);
    const [errors, setErrors] = React.useState({});
    const [processing, setProcessing] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const handleChange = React.useCallback(
        (e) => {
            if (customHandleChange) {
                return customHandleChange(e);
            } else {
                let name = e.target.name;

                let value = e.target.type === 'checkbox' ? +e.target.checked : e.target.value;

                let newData = { ...data };
                let newErrors = { ...errors };

                _.set(newData, name, value);
                delete newErrors[name];

                if (handleChangeMiddleware) {
                    let res = handleChangeMiddleware({
                        name,
                        value,
                        newData,
                        newErrors,
                    });

                    if (_.isPlainObject(res)) {
                        if (res.name) {
                            name = res.name;
                        }

                        if (res.value) {
                            value = res.value;
                        }

                        if (res.newData) {
                            newData = res.newData;
                        }

                        if (res.newErrors) {
                            newErrors = res.newErrors;
                        }
                    }
                }

                setData(newData);
                setErrors(newErrors);
            }
        },
        [data, errors, handleChangeMiddleware, customHandleChange]
    );

    const fieldProps = React.useCallback(
        (name) => {
            let dataValue = _.get(data, name);

            if (typeof dataValue == 'undefined' || null === dataValue) {
                dataValue = '';
            }

            let dataError = errors[name];

            if (typeof dataError == 'undefined') {
                dataError = false;
            }

            return {
                name,
                value: dataValue,
                error: dataError,
                loading,
                onChange: handleChange,
            };
        },
        [data, errors, loading, handleChange]
    );

    const filesUploadProps = React.useCallback(
        (name) => {
            let dataValue = _.get(data, name);

            if (typeof dataValue == 'undefined' || null === dataValue || !dataValue.length) {
                dataValue = [];
            }

            dataValue = dataValue.map((file) => {
                file.keyId = file.keyId ? file.keyId : Math.random();
                return file;
            });

            function getFilesList(data, path) {
                let files = _.get(data, path);
                if (!files) {
                    return [];
                }
                return files;
            }

            function handleUpload(uploadFile) {
                if (uploadFile.File) {
                    api.upload(uploadFile.File, {
                        onProgress: (e) => {
                            setData((oldData) => {
                                let newData = { ...oldData };
                                let newFiles = [...getFilesList(newData, name)];
                                newFiles = newFiles.map((file) =>
                                    file.keyId === uploadFile.keyId ? { ...file, loadedPercent: e.percent } : file
                                );
                                _.set(newData, name, newFiles);
                                return newData;
                            });
                        },
                    })
                        .then((uploadedFile) => {
                            setData((oldData) => {
                                let newData = { ...oldData };
                                let newFiles = [...getFilesList(newData, name)];
                                newFiles = newFiles.map((file) =>
                                    file.keyId === uploadFile.keyId ? { ...uploadedFile, keyId: file.keyId } : file
                                );
                                _.set(newData, name, newFiles);
                                return newData;
                            });
                        })
                        .catch((e) => {
                            setData((oldData) => {
                                let newData = { ...oldData };
                                let newFiles = [...getFilesList(newData, name)];
                                newFiles = newFiles.map((file) =>
                                    file.keyId === uploadFile.keyId ? { ...file, error: e.message } : file
                                );
                                _.set(newData, name, newFiles);
                                return newData;
                            });
                        });
                }
            }

            function handleDelete(deleteFile) {
                setData((oldData) => {
                    let newData = { ...oldData };
                    let newFiles = [...getFilesList(newData, name)];
                    newFiles = newFiles.filter((file) => file.keyId !== deleteFile.keyId);
                    _.set(newData, name, newFiles);
                    return newData;
                });
            }

            function handleAppend(appendFiles) {
                setData((oldData) => {
                    let newData = { ...oldData };
                    let newFiles = [...getFilesList(newData, name), ...appendFiles];
                    _.set(newData, name, newFiles);
                    return newData;
                });

                for (let file of appendFiles) {
                    handleUpload(file);
                }
            }

            return {
                files: dataValue,
                loading,
                onAppend: handleAppend,
                onDelete: handleDelete,
            };
        },
        [data, loading]
    );

    const isHasErrors = Object.keys(errors).length > 0;

    const formProps = React.useCallback(() => {
        return {
            processing,
            loading,
            error: isHasErrors,
        };
    }, [processing, loading, isHasErrors]);

    return {
        data,
        setData,
        errors,
        setErrors,
        processing,
        setProcessing,
        loading,
        setLoading,
        fieldProps,
        filesUploadProps,
        formProps,
        handleChange,
        isHasErrors,
    };
}

const useStyles = makeStyles({
    dialogTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
});

function DataForm({
    editing,
    processing,
    loading,
    error,
    errorText = 'Форма содержит ошибки',
    titleText = undefined,
    createText = 'Создание',
    editText = 'Редактирование',
    onOpen = () => null,
    onSave = () => null,
    saveButtonText = 'Сохранить',
    saveButtonDisabled = false,
    onClose = () => null,
    closeButtonText = 'Отмена',
    closeButtonDisabled = false,
    fullScreenDialog,
    children,
}) {
    const theme = useTheme();
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const needsFullScreen = !useMediaQuery(theme.breakpoints.up('md'));

    if (typeof fullScreenDialog == 'undefined') {
        fullScreenDialog = needsFullScreen;
    } else {
        fullScreenDialog = !!fullScreenDialog;
    }

    function handleClose() {
        if (!processing && !loading) {
            setOpen(false);
        }
    }

    return (
        <Dialog
            open={open}
            TransitionProps={{
                onEnter: onOpen,
                onExited: onClose,
            }}
            maxWidth="md"
            fullWidth
            fullScreen={fullScreenDialog}
        >
            <div className={classes.dialogTitle}>
                <DialogTitle>{titleText === undefined ? (editing ? editText : createText) : titleText}</DialogTitle>
                <div>
                    <IconButton onClick={handleClose} disabled={closeButtonDisabled || processing || loading}>
                        <CancelIcon />
                    </IconButton>
                </div>
            </div>
            {loading ? <LinearProgress /> : null}
            <DialogContent>{children}</DialogContent>
            <DialogActions style={{ position: 'sticky', bottom: 0 }}>
                {error ? (
                    <FormHelperText sx={{ mr: 1 }} error>
                        {errorText}
                    </FormHelperText>
                ) : null}
                <Button
                    onClick={onSave}
                    disabled={saveButtonDisabled || processing || loading}
                    startIcon={processing && <CircularProgress size={20} />}
                >
                    {saveButtonText}
                </Button>
                <Button disabled={closeButtonDisabled || processing || loading} variant="text" onClick={handleClose}>
                    {closeButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export { DataForm, useDataForm };
