import React from 'react';

import { observer } from 'mobx-react-lite';
import { dictionariesStore } from '../stores/dictionariesStore';
import { FormSimpleSelect } from './common/FormSimpleSelect';

const DeclarationStatusesSelect = observer((props) => {
    return <FormSimpleSelect list={dictionariesStore.declaration_statuses} {...props} />;
});

export { DeclarationStatusesSelect };
