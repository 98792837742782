import React from 'react';

import { Button, FormControl } from '@mui/material';

import { api } from '../../lib/api';
import { useDataForm } from '../../components/common/DataForm';
import { CustomDataTable, useCustomDataTable } from '../../components/CustomDataTable';
import { ForestriesSelect } from '../../components/ForestriesSelect';
import { FormNumber } from '../../components/common/FormNumber';
import { StructuresSelect } from '../../components/StructuresSelect';
import { CutoutsForm } from './Form';

import { useFormStyles } from '../../hooks/useFormStyles';

function FilterContent({ onFilter, initialData = {} }) {
    const classes = useFormStyles();
    const { data, fieldProps } = useDataForm({ initialData });

    function handleFilter() {
        onFilter(data);
    }

    return (
        <div>
            <div className={classes.fieldsGroup}>
                <ForestriesSelect {...fieldProps('forestry_id')} />
            </div>
            <div className={classes.fieldsGroup}>
                <FormNumber {...fieldProps('quarter')} label="Квартал" />
                <StructuresSelect {...fieldProps('structure_id')} />
                <FormControl>
                    <Button onClick={handleFilter}>Показать</Button>
                </FormControl>
            </div>
        </div>
    );
}

async function rowsLoader(params) {
    return (await api.cutoutsList({ ...params }))?.rows;
}

async function rowsDeleter(ids, params) {
    return await api.cutoutsDelete({ ...params, ids });
}

export function CutoutsList() {
    const [editId, setEditId] = React.useState(false);

    const columns = [
        { field: 'number', title: 'Лесосека', type: 'integer' },
        { field: 'created_on', title: 'Дата', type: 'date' },
        { field: 'forestry_name', title: 'Участковое лесничество' },
        { field: 'quarter', title: 'Квартал', type: 'integer' },
        { field: 'farm_name', title: 'Хозяйство' },
        { field: 'category_name', title: 'Категория', longContent: true },
        { field: 'felling_form_name', title: 'Форма рубки' },
        { field: 'felling_kind_name', title: 'Вид рубки', longContent: true },
        { field: 'selection_percent', title: 'Процент выборки', type: 'integer' },
        { field: 'activities', title: 'Хозяйственные мероприятия', longContent: true },
        { field: 'schema', title: 'Общая схема', type: 'image' },
        { field: 'schema_scale', title: 'Масштаб схемы' },
    ];

    const { tableProps, tableActions } = useCustomDataTable({
        columns,
        columnsStorageKey: 'columns:cutouts',
        rowsLoader,
        rowsDeleter,
    });

    function handleOnFilter(filterData) {
        tableActions.applyFilterData(filterData);
        tableActions.setFilterOpen(false);
    }

    return (
        <div>
            {false !== editId && (
                <CutoutsForm
                    objectId={editId}
                    onSave={() => {
                        setEditId(false);
                        tableActions.loadRows();
                    }}
                    onClose={() => setEditId(false)}
                />
            )}
            <CustomDataTable
                {...tableProps}
                onCreate={() => setEditId(null)}
                onEdit={(id) => setEditId(id)}
                filterContent={<FilterContent onFilter={handleOnFilter} initialData={tableProps.filterData} />}
            />
        </div>
    );
}
