import { authStore } from '../stores/authStore';
import { messagesStore } from '../stores/messagesStore';

class Api {
    constructor(resources) {
        if (resources && Array.isArray(resources)) {
            let that = this;
            resources.forEach((resource) => {
                that[resource.name + 'List'] = async (params) => {
                    return await that.request(resource.path, params, 'POST');
                };

                that[resource.name + 'Delete'] = async (params) => {
                    return await that.request(resource.path, params, 'DELETE');
                };

                that[resource.name + 'Create'] = async (params) => {
                    return await that.request(resource.path + '/create', params, 'POST');
                };

                that[resource.name + 'Edit'] = async (id, params) => {
                    return await that.request(resource.path + '/edit/' + id, params, 'POST');
                };

                that[resource.name + 'Get'] = async (id) => {
                    return await that.request(resource.path + '/edit/' + id, null, 'GET');
                };
            });
        }
    }

    getUrl(action) {
        return encodeURI(process.env.PUBLIC_URL + '/api/' + action);
    }

    async request(action, data, method, settings = {}) {
        let requestUrl = this.getUrl(action);
        let requestMethod = method || 'POST';

        const requestParams = {
            method: requestMethod,
            headers: { 'Content-Type': 'application/json' },
        };

        if (action !== 'login' && action !== 'password-reset') {
            if (authStore.key) {
                requestParams.headers.Authorization = 'Bearer ' + authStore.key;
            }
        }

        if (data) {
            requestParams.body = JSON.stringify(data);
        }

        try {
            let response = await fetch(requestUrl, requestParams);

            if (response.status === 401) {
                authStore.setUnauthorized();
                console.error(`API action: ${action} (${requestMethod}) failed: 401 Authorization required`);
                return null;
            }

            if (response.status >= 500) {
                throw Error('Internal Server Error');
            }

            response = await response.json();

            if (response.error) {
                throw new Error(response.error);
            }

            authStore.refreshAuthorization();

            return response;
        } catch (e) {
            console.error(`API action: ${action} (${requestMethod}) failed: ${e.message}`);
            if (settings.throwError) {
                throw e;
            }
        }

        messagesStore.setError500(true);

        return null;
    }

    async upload(fileObject, settings = {}) {
        return new Promise((resolve, reject) => {
            let action = 'upload';
            let requestMethod = 'POST';
            let requestUrl = this.getUrl(action);

            let data = new FormData();
            data.append('file', fileObject);

            let request = new XMLHttpRequest();

            request.open(requestMethod, requestUrl);

            request.setRequestHeader('Accept', 'application/json');

            if (authStore.key) {
                request.setRequestHeader('Authorization', 'Bearer ' + authStore.key);
            }

            request.upload.addEventListener('progress', (e) => {
                let percentCompleted = (e.loaded / e.total) * 100;
                if (settings.onProgress) {
                    settings.onProgress({
                        percent: percentCompleted,
                        loaded: e.loaded,
                        total: e.total,
                    });
                }
            });

            request.addEventListener('load', function (e) {
                if (request.status === 401) {
                    authStore.setUnauthorized();
                }

                try {
                    if (request.status === 413) {
                        throw new Error('Файл слишком большой для загрузки');
                    }

                    if (request.status < 100 || request.status >= 300) {
                        throw new Error('Неожиданная ошибка');
                    }

                    let response = JSON.parse(request.response);

                    if (response.error) {
                        throw new Error(response.error);
                    }

                    resolve(response);
                } catch (e) {
                    console.error(`API action: ${action} (${requestMethod}) failed: ${e.message}`);

                    reject(e);
                }
            });

            try {
                request.send(data);
            } catch (e) {
                reject(e);
            }
        });
    }

    /**
     * Функция авторизации в системе.
     * В случае успешной авторизации возвращает объект с ключём "key".
     * В случае возникновения ошибок возвращает объект с ключём "errors" содержащий
     * список ошибок в переданных полях.
     *
     * @param {string} login логин
     * @param {password} password пароль
     */
    async login(login, password) {
        return await this.request('login', { login, password });
    }

    /**
     * Функция устанавливает новый пароль для пользователя.
     *
     * Если передан только
     * логин и данный пользователь существует, ему высылается код на e-mail
     * для изменения пароля.
     *
     * Если передан и код и пароли, то пароль сбрасывается но указанный.
     *
     * При любых ошибках возвращается не пустой объект errors содержащий
     * ключи полей, в которых произошла ошибка и описание этой ошибки.
     *
     * Если ошибок не было, то для первого поведения возвращается ключ
     * codeSent: true
     *
     * Для второго случая возвращается ключ
     * passwordChanged: true
     *
     * @param {string} loginOrEmail логин или e-mail пользователя
     * @param {string} code высланный на e-mail код
     * @param {string} password новый пароль
     */
    async passwordReset(loginOrEmail, code, password) {
        return await this.request('password-reset', {
            login: loginOrEmail,
            code,
            password,
        });
    }

    async dictionaries(settings) {
        return await this.request('dictionaries', null, 'GET', settings);
    }

    async allotmentsQuarters(forestry_id, settings) {
        return await this.request(
            'allotments/quarters?forestry_id=' + encodeURIComponent(forestry_id),
            null,
            'GET',
            settings
        );
    }

    async allotmentsFarms(forestry_id, quarter, settings) {
        return await this.request(
            'allotments/farms?forestry_id=' +
                encodeURIComponent(forestry_id) +
                '&quarter=' +
                encodeURIComponent(quarter),
            null,
            'GET',
            settings
        );
    }

    async allotmentsCategories(forestry_id, quarter, farm_id, settings) {
        return await this.request(
            'allotments/categories?forestry_id=' +
                encodeURIComponent(forestry_id) +
                '&quarter=' +
                encodeURIComponent(quarter) +
                '&farm_id=' +
                encodeURIComponent(farm_id),
            null,
            'GET',
            settings
        );
    }

    async allotmentsCategoryComments(forestry_id, quarter, farm_id, category_id, settings) {
        return await this.request(
            'allotments/category-comments?forestry_id=' +
                encodeURIComponent(forestry_id) +
                '&quarter=' +
                encodeURIComponent(quarter) +
                '&farm_id=' +
                encodeURIComponent(farm_id) +
                '&category_id=' +
                encodeURIComponent(category_id),
            null,
            'GET',
            settings
        );
    }

    async allotmentsFellingKinds(forestry_id, quarter, farm_id, category_id, category_comments, settings) {
        return await this.request(
            'allotments/felling-kinds',
            { forestry_id, quarter, farm_id, category_id, category_comments },
            'POST',
            settings
        );
    }

    async allotmentsSelectionPercents(
        forestry_id,
        quarter,
        farm_id,
        category_id,
        category_comments,
        felling_kind_id,
        settings
    ) {
        return await this.request(
            'allotments/selection-percents',
            { forestry_id, quarter, farm_id, category_id, category_comments, felling_kind_id },
            'POST',
            settings
        );
    }

    async allotmentsDownloadReport(data, settings) {
        return await this.request('allotments/download-report', data, null, settings);
    }

    async cutoutsNextNumber(forestry_id, quarter, settings) {
        return await this.request('cutouts/next-number', { forestry_id, quarter }, 'POST', settings);
    }
}

const api = new Api([
    { name: 'adminAccounts', path: 'admin/accounts' },
    { name: 'forestries', path: 'forestries' },
    { name: 'woodmans', path: 'woodmans' },
    { name: 'districtForestries', path: 'district-forestries' },
    { name: 'allotments', path: 'allotments' },
    { name: 'cutouts', path: 'cutouts' },
    { name: 'declarations', path: 'declarations' },
]);

export { api };
