import React from 'react';

import { IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmDialog } from './ConfirmDialog';

export function DeleteButtonWithConfirmation({
    data = null,
    text = 'Вы уверены что хотите удалить?',
    tooltipText = 'Удалить',
    onDelete = () => null,
    disabled = false,
}) {
    const [open, setOpen] = React.useState(false);

    function handleClick() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    function handleDelete() {
        onDelete(data);
    }

    return (
        <React.Fragment>
            <Tooltip title={tooltipText}>
                <span>
                    <IconButton disabled={disabled} onClick={handleClick}>
                        <DeleteIcon />
                    </IconButton>
                </span>
            </Tooltip>
            <ConfirmDialog
                open={open}
                title="Подтвердите действие"
                text={text}
                onConfirm={handleDelete}
                onClose={handleClose}
            />
        </React.Fragment>
    );
}
