import React from 'react';

import { FormLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { DeleteButtonWithConfirmation } from '../../../components/DeleteButtonWithConfirmation';
import { PhotoWithPreview } from '../../../components/PhotoWithPreview';

export function CutoutsList({ cutouts, errors, disabled, onDelete = () => null }) {
    if (!cutouts.length) {
        return null;
    }

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Категория</TableCell>
                        <TableCell>Категория (комментарии)</TableCell>
                        <TableCell>Лесничество</TableCell>
                        <TableCell>Участковое лесничество</TableCell>
                        <TableCell>Квартал</TableCell>
                        <TableCell>Выделы</TableCell>
                        <TableCell>Лесосека</TableCell>
                        <TableCell>Форма рубки</TableCell>
                        <TableCell>Вид рубки</TableCell>
                        <TableCell>Хозяйство</TableCell>
                        <TableCell>Схема</TableCell>
                        <TableCell>Масштаб схемы</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {cutouts.map((cutout, index) => {
                        const cutoutData = cutout.cutout;
                        const cutoutSchemaError = errors['cutouts.' + index + '.schema'];

                        return (
                            <TableRow key={cutout.keyId}>
                                <TableCell>{cutoutData.category_name}</TableCell>
                                <TableCell>{cutoutData.category_comments}</TableCell>
                                <TableCell>{cutoutData.superior_forestry_name}</TableCell>
                                <TableCell>{cutoutData.forestry_name}</TableCell>
                                <TableCell>{cutoutData.quarter}</TableCell>
                                <TableCell>{cutoutData.allotments.map((a) => a.allotment_number).join(', ')}</TableCell>
                                <TableCell>{cutoutData.number}</TableCell>
                                <TableCell>{cutoutData.felling_form_name}</TableCell>
                                <TableCell>{cutoutData.felling_kind_name}</TableCell>
                                <TableCell>{cutoutData.farm_name}</TableCell>
                                <TableCell>
                                    {cutout?.schema ? (
                                        <PhotoWithPreview
                                            url={cutout.schema?.data ? cutout.schema?.data : cutout.schema}
                                        />
                                    ) : null}
                                    {cutoutSchemaError ? <FormLabel error>{cutoutSchemaError}</FormLabel> : null}
                                </TableCell>
                                <TableCell>{cutout.schema_scale}</TableCell>
                                <TableCell align="center">
                                    <DeleteButtonWithConfirmation
                                        data={index}
                                        disabled={disabled}
                                        onDelete={onDelete}
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
