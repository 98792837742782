import React from 'react';
import ReactDOM from 'react-dom';

import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { observer } from 'mobx-react-lite';
import { ruRU } from '@mui/material/locale';

import moment from 'moment';
import 'moment/locale/ru';

import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { messagesStore } from './stores/messagesStore';
import { LoginScreen } from './pages/LoginScreen/LoginScreen';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { Error500, SuccessMessage } from './components/common/Snackbars';

const locale = 'ru';

moment.locale(locale);

const defaultTheme = createTheme();

const defaultTypography = defaultTheme.typography;

const theme = createTheme(
    {
        components: {
            MuiFormControl: {
                defaultProps: { size: 'small', margin: 'dense' },
            },
            MuiButton: {
                defaultProps: { variant: 'contained' },
            },
            MuiTable: {
                defaultProps: { size: 'small' },
            },
            MuiTableCell: {
                styleOverrides: {
                    head: { fontWeight: defaultTypography.fontWeightBold },
                    sizeSmall: { paddingLeft: 3, paddingRight: 6, paddingTop: 1, paddingBottom: 1 },
                    root: {
                        fontSize: defaultTypography.pxToRem(defaultTypography.fontSize * 0.8),
                    },
                },
            },
        },
    },
    ruRU
);

const App = observer(() => {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment} locale={locale}>
            <ThemeProvider theme={theme}>
                <StyledEngineProvider injectFirst>
                    <CssBaseline />
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<LoginScreen />} />
                            <Route path="/*" element={<Dashboard />} />
                        </Routes>
                    </BrowserRouter>
                    <Error500 open={messagesStore.isError500} onClose={() => messagesStore.setError500(false)} />
                    <SuccessMessage
                        open={messagesStore.isSuccessSaved}
                        onClose={() => messagesStore.setSuccessSaved(false)}
                    />
                </StyledEngineProvider>
            </ThemeProvider>
        </LocalizationProvider>
    );
});

ReactDOM.render(<App />, document.getElementById('root'));
