import React from 'react';

import { api } from '../../lib/api';
import { CustomDataTable, useCustomDataTable } from '../../components/CustomDataTable';
import { TextField, FormControl, Button } from '@mui/material';

import { AdminAccountsForm } from './Form';
import { useFormStyles } from '../../hooks/useFormStyles';

async function rowsLoader(params) {
    return (await api.adminAccountsList({ ...params }))?.rows;
}

async function rowsDeleter(ids, params) {
    return await api.adminAccountsDelete({ ...params, ids });
}

function FilterContent({ onFilter, initialData = {} }) {
    const classes = useFormStyles();
    const [filter, setFilter] = React.useState(initialData);

    function handleFilter() {
        onFilter(filter);
    }

    function handleChange(e) {
        setFilter({ filter: e.target.value });
    }

    return (
        <div className={classes.fieldsGroup}>
            <TextField value={filter.filter} onChange={handleChange} label="Поиск" />
            <FormControl>
                <Button onClick={handleFilter}>Показать</Button>
            </FormControl>
        </div>
    );
}

function AdminAccountsList() {
    const columns = [
        { field: 'login', title: 'Логин' },
        { field: 'name', title: 'Имя' },
        { field: 'email', title: 'E-mail' },
        {
            field: 'is_disabled',
            title: 'Заблокирован?',
            type: 'boolean',
        },
        { field: 'created_at', type: 'datetime', title: 'Дата создания' },
        { field: 'auth_at', type: 'datetime', title: 'Дата входа' },
    ];

    const [editId, setEditId] = React.useState(false);

    const { tableProps, tableActions } = useCustomDataTable({
        columns,
        rowsLoader,
        rowsDeleter,
        filterData: { filter: '' },
    });

    function handleOnFilter(value) {
        tableActions.applyFilterData(value);
        tableActions.setFilterOpen(false);
    }

    return (
        <div>
            {false !== editId && (
                <AdminAccountsForm
                    objectId={editId}
                    onSave={() => {
                        setEditId(false);
                        tableActions.loadRows();
                    }}
                    onClose={() => setEditId(false)}
                />
            )}
            <CustomDataTable
                {...tableProps}
                filterContent={<FilterContent onFilter={handleOnFilter} initialData={tableProps.filterData} />}
                onCreate={() => setEditId(null)}
                onEdit={(id) => setEditId(id)}
            />
        </div>
    );
}

export { AdminAccountsList };
