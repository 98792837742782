import _ from 'lodash';

import {
    gpsConvertDecToDeg,
    gpsConvertDegToDec,
    gpsCleanDecValue,
    DEFAULT_GPS_FORMAT,
    GPS_FORMAT_DEC,
    GPS_FORMAT_DEG,
} from '../../components/common/FormGps';

export function enumerateCoords(coords) {
    const coordTypes = _.groupBy(coords, (coord) => coord.type);
    const newCoords = [];
    for (let type of Object.keys(coordTypes).sort((a, b) => +a - +b)) {
        coordTypes[type].forEach((coord) => {
            delete coord.autoFocus;
            newCoords.push(coord);
        });
    }
    return newCoords;
}

export function enumerateGps(coords) {
    const coordTypes = _.groupBy(coords, (coord) => coord.type);
    const newCoords = [];
    const types = Object.keys(coordTypes).sort((a, b) => +a - +b);
    for (let type of types) {
        for (let coord of coordTypes[type]) {
            delete coord.autoFocus;
            newCoords.push(coord);
        }
    }
    return newCoords;
}

export function normalizeDataFromBackend(data) {
    return normalizeBackendData(data, 'in');
}

export function normalizeDataToBackend(data) {
    return normalizeBackendData(data, 'out');
}

export function gpsConvert(gps, fromFormat, toFormat, latitudeKey = 'latitude', longitudeKey = 'longitude') {
    if (!fromFormat) {
        fromFormat = DEFAULT_GPS_FORMAT;
    }

    if (!toFormat) {
        toFormat = DEFAULT_GPS_FORMAT;
    }

    if (fromFormat === GPS_FORMAT_DEG && toFormat === GPS_FORMAT_DEC) {
        gps[latitudeKey] = gpsConvertDegToDec(gps[latitudeKey]);
    } else if (fromFormat === GPS_FORMAT_DEC && toFormat === GPS_FORMAT_DEG) {
        gps[latitudeKey] = gpsConvertDecToDeg(gps[latitudeKey], 'latitude');
    } else if (fromFormat === GPS_FORMAT_DEC && toFormat === GPS_FORMAT_DEC) {
        gps[latitudeKey] = gpsCleanDecValue(gps[latitudeKey]);
    }

    if (fromFormat === GPS_FORMAT_DEG && toFormat === GPS_FORMAT_DEC) {
        gps[longitudeKey] = gpsConvertDegToDec(gps[longitudeKey]);
    } else if (fromFormat === GPS_FORMAT_DEC && toFormat === GPS_FORMAT_DEG) {
        gps[longitudeKey] = gpsConvertDecToDeg(gps[longitudeKey], 'longitude');
    } else if (fromFormat === GPS_FORMAT_DEC && toFormat === GPS_FORMAT_DEC) {
        gps[longitudeKey] = gpsCleanDecValue(gps[longitudeKey]);
    }

    return gps;
}

export function gpsListConvert(list, fromFormat, toFormat) {
    if (list?.length > 0) {
        for (let i in list) {
            gpsConvert(list[i], fromFormat, toFormat);
        }
    }
}

export function storageGpsConvert(gps, fromFormat, toFormat) {
    return gpsConvert(gps, fromFormat, toFormat, 'storage_latitude', 'storage_longitude');
}

function normalizeBackendData(srcData, direction = 'in') {
    let data = _.cloneDeep(srcData);

    if (!Array.isArray(data?.structures)) {
        data.structures = [];
    }

    data.structures.forEach((v) => (v.keyId = v.keyId ?? v.id ?? Math.random()));

    /* Координаты азимуты/румбы */
    if (!Array.isArray(data.coords)) {
        data.coords = [];
    }

    data.coords.forEach((v) => (v.keyId = v.keyId ?? v.id ?? Math.random()));
    data.coords = enumerateCoords(data.coords);

    /* Координаты GPS */
    if (!Array.isArray(data.gps)) {
        data.gps = [];
    }

    data.gps.forEach((v) => (v.keyId = v.keyId ?? v.id ?? Math.random()));
    data.gps = enumerateGps(data.gps);

    if (!data.gps_format) {
        data.gps_format = DEFAULT_GPS_FORMAT;
    }

    if (direction === 'in') {
        gpsListConvert(data?.gps, GPS_FORMAT_DEC, data.gps_format);
    } else {
        gpsListConvert(data?.gps, data.gps_format, GPS_FORMAT_DEC);
    }

    /* Координаты складирования */
    if (!data.storage_gps_format) {
        data.storage_gps_format = DEFAULT_GPS_FORMAT;
    }

    if (direction === 'in') {
        storageGpsConvert(data, GPS_FORMAT_DEC, data.storage_gps_format);
    } else {
        storageGpsConvert(data, data.storage_gps_format, GPS_FORMAT_DEC);
    }

    return data;
}

export function recalculateStructure(data, changedField) {
    const structure = { ...data };

    if (!['is_dead', 'reserve_small', 'reserve_medium', 'reserve_big', 'reserve_dead'].includes(changedField)) {
        return structure;
    }

    let small = parseInt(data.reserve_small);
    small = isNaN(small) ? 0 : small;

    let medium = parseInt(data.reserve_medium);
    medium = isNaN(medium) ? 0 : medium;

    let big = parseInt(data.reserve_big);
    big = isNaN(big) ? 0 : big;

    let dead = parseInt(data.reserve_dead);
    dead = isNaN(dead) ? 0 : dead;

    if (data.is_dead) {
        structure.reserve_wet = dead;
    } else {
        structure.reserve_wet = small + medium + big + dead;
    }

    return structure;
}
