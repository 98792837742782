import React from 'react';

import { FormSimpleSelect } from './common/FormSimpleSelect';

function CoordTypesSelect(props) {
    const list = [
        { id: 1, name: 'Привязка' },
        { id: 2, name: 'Лесосека' },
    ];

    return <FormSimpleSelect list={list} {...props} />;
}

export { CoordTypesSelect };
