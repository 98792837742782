import React from 'react';

import { observer } from 'mobx-react-lite';

import { makeStyles } from '@mui/styles';
import {
    AppBar,
    Drawer,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Typography,
    SwipeableDrawer,
    Breadcrumbs,
    Collapse,
    Tooltip,
    Dialog,
    DialogContent,
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SettingsIcon from '@mui/icons-material/Settings';
import LayersIcon from '@mui/icons-material/Layers';
import NaturePeopleIcon from '@mui/icons-material/NaturePeople';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExtensionIcon from '@mui/icons-material/Extension';
import PeopleIcon from '@mui/icons-material/People';
import ListAltIcon from '@mui/icons-material/ListAlt';

import { Routes, Route, useNavigate, useLocation, matchPath, Navigate, Link as RouterLink } from 'react-router-dom';

import mainmenuBg from '../../pics/menubg.jpg';
import toolbarBg from '../../pics/toolbarbg.png';

import { ConfirmDialog } from '../../components/ConfirmDialog';
import { PageHeader } from '../../components/PageHeader';
import { Copyright } from '../../components/Copyright';

import { authStore } from '../../stores/authStore';
import { dictionariesStore } from '../../stores/dictionariesStore';
import { AdminAccountsList } from '../../pages/AdminAccounts/List';
import { ForestriesList } from '../../pages/Forestries/List';
import { AllotmentsList } from '../../pages/Allotments/List';
import { CutoutsList } from '../../pages/Cutouts/List';
import { WoodmansList } from '../../pages/Woodmans/List';
import { DeclarationsList } from '../../pages/Declarations/List';
import { LoginDialog } from '../../pages/LoginScreen/LoginDialog';

import { DEFAULT_ROUTE } from '../../constants/routes';

const drawerWidth = 280;
const drawerBreakpoint = 'xl';

const useStyles = makeStyles((theme) => ({
    appBar: {
        background: 'no-repeat url(' + toolbarBg + ')',
        backgroundSize: 'cover',
        [theme.breakpoints.up(drawerBreakpoint)]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    nav: {
        [theme.breakpoints.up(drawerBreakpoint)]: {
            width: drawerWidth,
        },
    },
    main: {
        width: '100%',
        [theme.breakpoints.up(drawerBreakpoint)]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(drawerBreakpoint)]: {
            display: 'none',
        },
    },
    mainContent: {
        padding: theme.spacing(1.5),
    },
    imgLogo: {
        maxHeight: 30,
        marginRight: theme.spacing(2),
    },
    toolbarSpacer: { height: 48 },
    toolbarTitle: {
        flexGrow: 1,
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-end',
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        color: 'rgba(255, 255, 255, 0.8)',
        background: 'no-repeat url(' + mainmenuBg + ')',
        backgroundSize: 'cover',
    },
    breadcrumbs: {
        display: 'flex',
        marginBottom: theme.spacing(1),
    },
    menuItemButton: {
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
        },
    },
    menuItemIcon: {
        color: 'rgba(255, 255, 255, 0.8)',
    },
    menuNestedList: {
        marginLeft: theme.spacing(2),
    },
    loginDialog: {
        zIndex: theme.zIndex.modal + 1,
    },
}));

function LinkRouter(props) {
    return <Link {...props} component={RouterLink} />;
}

function CustomListItem(props) {
    const classes = useStyles();
    return <ListItem button classes={{ button: classes.menuItemButton }} {...props} />;
}

function CustomListItemIcon(props) {
    const classes = useStyles();
    return <ListItemIcon classes={{ root: classes.menuItemIcon }} {...props} />;
}

function SimpleMenuItem({ onClick, icon, title, children }) {
    return (
        <CustomListItem onClick={onClick}>
            <CustomListItemIcon>{icon}</CustomListItemIcon>
            <ListItemText primary={title} />
            {children}
        </CustomListItem>
    );
}

function MenuItems({ onClose = () => null }) {
    const classes = useStyles();
    const navigate = useNavigate();

    const [open, setOpen] = React.useState({});

    function closeAndGo(url) {
        onClose(url);

        if (url) {
            navigate(url);
        }
    }

    function toggle(section) {
        let isOpen = open[section] ? false : true;
        setOpen({ ...open, [section]: isOpen });
    }

    return (
        <div>
            <div className={classes.toolbarSpacer} />
            <List>
                <SimpleMenuItem title="Администрирование" onClick={() => toggle('admin')} icon={<SettingsIcon />}>
                    {open['admin'] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </SimpleMenuItem>
                <Collapse in={open['admin']}>
                    <List className={classes.menuNestedList}>
                        <SimpleMenuItem
                            title="Аккаунты"
                            onClick={() => closeAndGo('/admin/accounts')}
                            icon={<AccountBoxIcon />}
                        />
                    </List>
                </Collapse>
                <SimpleMenuItem
                    title="Лесничества"
                    onClick={() => closeAndGo('/forestries')}
                    icon={<NaturePeopleIcon />}
                />
                <SimpleMenuItem title="Лесники" onClick={() => closeAndGo('/woodmans')} icon={<PeopleIcon />} />
                <SimpleMenuItem title="Таксация" onClick={() => closeAndGo('/allotments')} icon={<LayersIcon />} />
                <SimpleMenuItem title="Лесосеки" onClick={() => closeAndGo('/cutouts')} icon={<ExtensionIcon />} />
                <SimpleMenuItem title="Декларации" onClick={() => closeAndGo('/declarations')} icon={<ListAltIcon />} />
            </List>
        </div>
    );
}

function DashboardBreadcrumbs({ routesMap }) {
    const classes = useStyles();
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);

    const breadcrumbs = [];
    for (let i = pathnames.length - 1; i >= 0; i--) {
        const to = pathnames.slice(0, i + 1).join('/');
        const route = routesMap.find((route) => matchPath(to, route.path));
        if (route) {
            let exist = breadcrumbs.findIndex((b) => b.path === route.path);
            if (exist < 0) {
                breadcrumbs.unshift({
                    path: route.path,
                    title: route.title,
                    to,
                    isLast: breadcrumbs.length === 0,
                    isDisabled: breadcrumbs.length === 0 || !route.element,
                });
            }
        }
    }

    return (
        <Breadcrumbs className={classes.breadcrumbs}>
            {breadcrumbs.map((b) => {
                return b.isDisabled ? (
                    <Typography color={b.isLast ? 'textPrimary' : 'textSecondary'} key={b.to}>
                        {b.title}
                    </Typography>
                ) : (
                    <LinkRouter color="inherit" to={b.to} key={b.to}>
                        {b.title}
                    </LinkRouter>
                );
            })}
        </Breadcrumbs>
    );
}

export const Dashboard = observer(() => {
    const classes = useStyles();

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [logoutOpen, setLogoutOpen] = React.useState(false);

    const navigate = useNavigate();

    const isAuthorized = authStore.isAuthorized;

    React.useEffect(() => {
        if (isAuthorized) {
            dictionariesStore.loadDictionaries();
        }
    }, [isAuthorized]);

    function createRoute(path, title, element) {
        return { path, title, element };
    }

    const routesMap = [
        createRoute('/admin', 'Администрирование', null),
        createRoute('/admin/accounts', 'Аккаунты', <AdminAccountsList />),
        createRoute('/forestries', 'Лесничества', <ForestriesList />),
        createRoute('/woodmans', 'Лесники', <WoodmansList />),
        createRoute('/allotments', 'Таксация', <AllotmentsList />),
        createRoute('/cutouts', 'Лесосеки', <CutoutsList />),
        createRoute('/declarations', 'Декларации', <DeclarationsList />),
    ];

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    function handleLogout() {
        setLogoutOpen(true);
    }

    return (
        <React.Fragment>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar variant="dense">
                    <IconButton
                        color="inherit"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <div className={classes.toolbarTitle}>
                        <Typography sx={{ mt: 0.5 }} variant="body2">
                            Система управления лесным хозяйством
                        </Typography>
                    </div>
                    <Tooltip title="Выйти из системы">
                        <IconButton color="inherit" onClick={handleLogout}>
                            <ExitToAppIcon />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>
            <nav className={classes.nav}>
                <SwipeableDrawer
                    sx={{ display: { xs: 'block', [drawerBreakpoint]: 'none' } }}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    onOpen={handleDrawerToggle}
                    classes={{ paper: classes.drawerPaper }}
                    ModalProps={{ keepMounted: true }}
                >
                    <MenuItems onClose={() => setMobileOpen(false)} />
                </SwipeableDrawer>
                <Drawer
                    sx={{ display: { xs: 'none', [drawerBreakpoint]: 'block' } }}
                    classes={{ paper: classes.drawerPaper }}
                    variant="permanent"
                    open
                >
                    <MenuItems />
                </Drawer>
            </nav>
            <main className={classes.main}>
                <div className={classes.toolbarSpacer} />
                <div className={classes.mainContent}>
                    <DashboardBreadcrumbs routesMap={routesMap} />
                    <Routes>
                        {routesMap.map((route) => {
                            return (
                                <Route key={route.path} path={route.path} element={route.element} {...route.props} />
                            );
                        })}
                        <Route path="/" render={() => <Navigate to={DEFAULT_ROUTE} />} />
                        <Route
                            path="*"
                            element={
                                <div>
                                    <PageHeader>404 не найдена</PageHeader>
                                    <Typography variant="body1" paragraph>
                                        Извините, страница с таким адресом не найдена на сервере.
                                    </Typography>
                                </div>
                            }
                        />
                    </Routes>
                    <Copyright style={{ marginTop: 'auto' }} />
                </div>
            </main>
            <ConfirmDialog
                open={logoutOpen}
                title="Выход из системы"
                text="Вы уверены что хотите выйти из системы?"
                onConfirm={() => {
                    authStore.setUnauthorized();
                    navigate('/');
                }}
                onClose={() => setLogoutOpen(false)}
            />
            <Dialog open={!authStore.isAuthorized} className={classes.loginDialog} maxWidth="xs">
                <DialogContent>
                    <LoginDialog />
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
});
