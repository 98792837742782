import React from 'react';

import { observer } from 'mobx-react-lite';
import { Button, FormControl, IconButton, Tooltip, useTheme } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { api } from '../../lib/api';
import { useMountedState } from '../../hooks/useMountedState';
import { dictionariesStore } from '../../stores/dictionariesStore';
import { useDataForm } from '../../components/common/DataForm';
import { CustomDataTable, useCustomDataTable } from '../../components/CustomDataTable';
import { ForestriesSelect } from '../../components/ForestriesSelect';
import { ForestTypesSelect } from '../../components/ForestTypesSelect';
import { FormNumber } from '../../components/common/FormNumber';
import { StructuresSelect } from '../../components/StructuresSelect';
import { AllotmentsForm } from './Form';
import { StructuresList } from './List/StructuresList';
import { FormText } from '../../components/common/FormText';
import { AllotmentCategoriesSelect } from '../../components/AllotmentCategoriesSelect';
import { FarmsSelect } from '../../components/FarmsSelect';
import { ExcelIcon } from '../../components/ExcelIcon';

import { useFormStyles } from '../../hooks/useFormStyles';

function FilterContent({ onFilter = () => null, onReport = () => null, initialData = {} }) {
    const theme = useTheme();
    const classes = useFormStyles();
    const { data, fieldProps } = useDataForm({ initialData });

    function handleFilter() {
        onFilter(data);
    }

    function handleReport() {
        onReport(data);
    }

    return (
        <div>
            <div className={classes.fieldsGroup}>
                <ForestriesSelect {...fieldProps('forestry_id')} style={{ minWidth: 300 }} />
                <FormNumber {...fieldProps('quarter')} label="Квартал" />
                <FormText {...fieldProps('bonitet')} label="Бонитет" />
                <FarmsSelect {...fieldProps('farm_id')} />
                <StructuresSelect {...fieldProps('structure_id')} />
                <AllotmentCategoriesSelect {...fieldProps('category_id')} />
                <ForestTypesSelect {...fieldProps('forest_type_id')} style={{ minWidth: 300 }} />
            </div>
            <div className={classes.fieldsGroup}>
                <FormControl>
                    <Button style={{ marginRight: theme.spacing(1) }} onClick={handleFilter}>
                        Показать
                    </Button>
                </FormControl>
                <FormControl>
                    <Button startIcon={<ExcelIcon />} onClick={handleReport} disabled={!data?.forestry_id}>
                        Получить отчёт
                    </Button>
                </FormControl>
            </div>
        </div>
    );
}

async function rowsLoader(params) {
    return (await api.allotmentsList({ ...params }))?.rows;
}

async function rowsDeleter(ids, params) {
    return await api.allotmentsDelete({ ...params, ids });
}

const AllotmentsList = observer(() => {
    const [editId, setEditId] = React.useState(false);
    const forestTypes = dictionariesStore.forest_types;
    const isMounted = useMountedState();

    const columns = [
        { field: 'quarter', title: 'Квартал', type: 'integer' },
        { field: 'number', title: 'Выдел', type: 'integer' },
        { field: 'farm_name', title: 'Хозяйство' },
        { field: 'category_name', title: 'Категория', longContent: true },
        { field: 'square', title: 'Площадь (га)', type: 'number' },
        { field: 'felling_form_name', title: 'Форма рубки' },
        { field: 'felling_kind_name', title: 'Вид рубки', longContent: true },
        { field: 'selection_percent', title: 'Процент выборки', type: 'integer' },
        { field: 'structure_code', title: 'Состав (код)' },
        {
            field: 'reserve_wet_ha',
            title: 'Корневой запас на 1 га, м³',
            type: 'integer',
        },
        {
            field: 'reserve_wet',
            title: 'Корневой запас общий на выдел, м³',
            type: 'integer',
        },
        { field: 'description', title: 'Описание', type: 'multiline' },
        { field: 'tier', title: 'Ярус', type: 'integer' },
        { field: 'tier_height', title: 'Высота яруса', type: 'integer' },
        { field: 'age', title: 'Возраст', type: 'integer' },
        { field: 'age_class', title: 'Класс возраста', type: 'integer' },
        { field: 'age_group', title: 'Группа возраста', type: 'integer' },
        { field: 'bonitet', title: 'Бонитет' },
        { field: 'fullness', title: 'Полнота', type: 'number' },
        {
            field: 'forest_types',
            title: 'Тип леса ТЛУ',
            sortable: false,
            formatValue: ({ value }) =>
                value
                    ?.map((id) => forestTypes.find((ft) => +ft.id === +id)?.name)
                    .filter((v) => v?.length > 0)
                    .join(', '),
            longContent: true,
        },
        { field: 'activities', title: 'Хозяйственные мероприятия', longContent: true },
        {
            field: 'undergrowth_count',
            title: 'Подрост, кол-во',
            type: 'integer',
        },
        {
            field: 'undergrowth_height',
            title: 'Подрост, высота',
            type: 'integer',
        },
        {
            field: 'undergrowth_age',
            title: 'Подрост, возраст',
            type: 'integer',
        },
        { field: 'undergrowth_structure_name', title: 'Подрост, порода' },
    ];

    const { tableProps, tableActions } = useCustomDataTable({
        columns,
        columnsStorageKey: 'columns:allotments',
        rowsLoader,
        rowsDeleter,
    });

    function handleOnFilter(filterData) {
        tableActions.applyFilterData(filterData);
        tableActions.setFilterOpen(false);
    }

    function handleReport(filterData) {
        tableActions.setFilterOpen(false);
        tableActions.setLoading(true);

        (async () => {
            try {
                const response = await api.allotmentsDownloadReport(filterData);

                if (response && response.url) {
                    let link = document.createElement('a');
                    link.href = response.url;
                    link.download = response.name;
                    link.target = '_blank';
                    link.click();
                }
            } catch (e) {
            } finally {
                if (isMounted()) {
                    tableActions.setLoading(false);
                }
            }
        })();
    }

    const [toggleStructures, setToggleStructures] = React.useState({});

    function renderRowButtons({ row, rowId }) {
        if (row.structures.length <= 0) {
            return null;
        }

        const toggle = () =>
            setToggleStructures((toggles) => ({
                ...toggles,
                [rowId]: !toggles[rowId],
            }));

        return (
            <Tooltip title="Показать породу">
                <IconButton onClick={toggle} size="small">
                    {!toggleStructures[rowId] ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                </IconButton>
            </Tooltip>
        );
    }

    function renderAfterRow({ row, rowId }) {
        return <StructuresList row={row} colspan={columns.length} show={toggleStructures[rowId]} />;
    }

    return (
        <div>
            {false !== editId && (
                <AllotmentsForm
                    objectId={editId}
                    onSave={() => {
                        setEditId(false);
                        tableActions.loadRows();
                    }}
                    onClose={() => setEditId(false)}
                />
            )}
            <CustomDataTable
                {...tableProps}
                onCreate={() => setEditId(null)}
                onEdit={(id) => setEditId(id)}
                filterContent={
                    <FilterContent
                        onFilter={handleOnFilter}
                        onReport={handleReport}
                        initialData={tableProps.filterData}
                    />
                }
                renderRowButtons={renderRowButtons}
                renderAfterRow={renderAfterRow}
            />
        </div>
    );
});

export { AllotmentsList };
