import React from 'react';

import { api } from '../../../lib/api';
import { DataForm, useDataForm } from '../../../components/common/DataForm';
import { ForestriesSelect } from '../../../components/ForestriesSelect';
import { QuartersSelect } from '../../../components/QuartersSelect';
import { CutoutsSelect } from '../../../components/CutoutsSelect';
import { FormOneImageUpload } from '../../../components/common/FormOneImageUpload';

import { useFormStyles } from '../../../hooks/useFormStyles';
import { SchemaScaleSelect } from '../../../components/SchemaScaleSelect';

export function CutoutsAddForm({ currentCutouts = [], onSave = () => null, onClose = () => null }) {
    const classes = useFormStyles();

    const [quarters, setQuarters] = React.useState([]);
    const [loadingQuarters, setLoadingQuarters] = React.useState(false);

    const [cutouts, setCutouts] = React.useState([]);
    const [loadingCutouts, setLoadingCutouts] = React.useState(false);

    const { data, setLoading, formProps, fieldProps } = useDataForm({
        handleChangeMiddleware: ({ name, newData }) => {
            if (name === 'forestry_id' || !newData.forestry_id) {
                newData.quarter = '';
            }

            if (name === 'quarter') {
                if (!newData.quarter) {
                    newData.cutout_id = [];
                } else {
                    const existingCutout = currentCutouts.find(
                        (cutout) =>
                            cutout.cutout.forestry_id === newData.forestry_id &&
                            cutout.cutout.quarter === newData.quarter
                    );

                    if (existingCutout) {
                        newData.schema = existingCutout.schema;
                        newData.schema_scale = existingCutout.schema_scale;
                    }
                }
            }

            return { newData };
        },
    });

    React.useEffect(() => {
        let discard = false;

        (async () => {
            if (data.forestry_id) {
                try {
                    setLoading(true);
                    setLoadingQuarters(true);
                    let res = await api.allotmentsQuarters(data.forestry_id);
                    setQuarters(res ?? []);
                } finally {
                    if (!discard) {
                        setLoadingQuarters(false);
                        setLoading(false);
                    }
                }
            }
        })();

        return () => (discard = true);
    }, [setLoading, data.forestry_id]);

    React.useEffect(() => {
        let discard = false;

        (async () => {
            if (data.forestry_id && data.quarter) {
                try {
                    setLoading(true);
                    setLoadingCutouts(true);

                    let res = await api.cutoutsList({
                        filterData: {
                            forestry_id: data.forestry_id,
                            quarter: data.quarter,
                        },
                        orderBy: 'number',
                    });

                    setCutouts(res?.rows ?? []);
                } finally {
                    if (!discard) {
                        setLoadingCutouts(false);
                        setLoading(false);
                    }
                }
            }
        })();

        return () => (discard = true);
    }, [setLoading, data.forestry_id, data.quarter]);

    function handleSave() {
        const selectedCutouts = cutouts.filter((v) => data.cutout_id.indexOf(v.id) !== -1);
        onSave(data, selectedCutouts);
    }

    const cutoutsDisabled = !data?.quarter;
    const cutoutsList = cutouts.map((v) => ({ id: v.id, name: v.number + '' }));

    const saveButtonDisabled = cutoutsDisabled || !data?.cutout_id?.length || !data.schema || !data.schema_scale;

    return (
        <DataForm
            {...formProps()}
            onSave={handleSave}
            onClose={onClose}
            titleText="Выберите лесосеки для добавления"
            saveButtonText="Добавить лесосеки"
            saveButtonDisabled={saveButtonDisabled}
        >
            <ForestriesSelect {...fieldProps('forestry_id')} required />
            <div className={classes.fieldsGroup}>
                <QuartersSelect
                    {...fieldProps('quarter')}
                    loading={loadingQuarters}
                    disabled={!data.forestry_id}
                    list={quarters}
                    required
                />
                <CutoutsSelect
                    {...fieldProps('cutout_id')}
                    loading={loadingCutouts}
                    disabled={cutoutsDisabled}
                    list={cutoutsList}
                    required
                />
            </div>
            <FormOneImageUpload {...fieldProps('schema')} label="Схема" required />
            <SchemaScaleSelect {...fieldProps('schema_scale')} required />
        </DataForm>
    );
}
