import React from 'react';

import moment from 'moment';

import { Chip, IconButton, Tooltip } from '@mui/material';
import ArchiveIcon from '@mui/icons-material/Archive';

import { api } from '../../lib/api';
import { CustomDataTable, useCustomDataTable } from '../../components/CustomDataTable';
import { DeclarationsForm } from './Form';
import { DownloadFileLink } from '../../components/DownloadFileLink';

async function rowsLoader(params) {
    return (await api.declarationsList({ ...params }))?.rows;
}

async function rowsDeleter(ids, params) {
    return await api.declarationsDelete({ ...params, ids });
}

function DeclarationsList() {
    const [editId, setEditId] = React.useState(false);

    const columns = [
        { field: 'created_on', title: 'Дата создания', type: 'date' },
        { field: 'number', title: 'Номер', type: 'integer' },
        {
            field: 'filed_on',
            title: 'Дата подачи',
            type: 'date',
            renderValue: ({ formattedValue, row }) => {
                let originalValue = row.filed_on;

                if (!formattedValue) {
                    return formattedValue;
                }

                let daysBetween = moment().diff(moment(originalValue), 'days');

                if (daysBetween > 5 && !row.status_id) {
                    return <Chip label={formattedValue} color="secondary" size="small" />;
                } else {
                    return formattedValue;
                }
            },
        },
        { field: 'validity_start_on', title: 'Действует с', type: 'date' },
        { field: 'validity_end_on', title: 'Действует по', type: 'date' },
        { field: 'status_name', title: 'Статус' },
        { field: 'status_on', title: 'Дата', type: 'date' },
        { field: 'status_comments', title: 'Комментарии', type: 'multiline' },
        {
            field: 'files',
            title: 'Файлы',
            sortable: false,
            formatValue: ({ row }) => row.files.map((file) => <DownloadFileLink key={file.id} {...file} />),
        },
    ];

    const { tableProps, tableActions } = useCustomDataTable({ columns, rowsLoader, rowsDeleter });

    function renderRowButtons({ row }) {
        return (
            <>
                <Tooltip title="Сгенерировать декларацию">
                    <IconButton download href={'/api/declarations/generate/' + row.id} size="small">
                        <ArchiveIcon />
                    </IconButton>
                </Tooltip>
            </>
        );
    }

    return (
        <div>
            {false !== editId && (
                <DeclarationsForm
                    objectId={editId}
                    onSave={() => {
                        setEditId(false);
                        tableActions.loadRows();
                    }}
                    onClose={() => setEditId(false)}
                />
            )}
            <CustomDataTable
                {...tableProps}
                onCreate={() => setEditId(null)}
                onEdit={(id) => setEditId(id)}
                renderRowButtons={renderRowButtons}
            />
        </div>
    );
}

export { DeclarationsList };
