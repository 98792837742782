import React from 'react';

import { Autocomplete, FormControlLabel, Checkbox, Popper } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { FormText } from './FormText';

const useStyles = makeStyles({
    root: {
        minWidth: 230,
        maxWidth: 'calc(100vw - 50px)',
    },
});

function CustomPopper(props) {
    return <Popper {...props} style={{ width: 'auto' }} placement="bottom-start" />;
}

export function FormAutocomplete({
    freeSolo = false,
    multiple = false,
    list = [],
    name,
    value,
    disabled,
    loading,
    required,
    fullWidth = false,
    onChange = () => null,
    label,
    ...props
}) {
    const classes = useStyles();
    const optionsIds = [];
    const optionsMap = new Map();

    if (list && list?.length) {
        list.forEach((opt) => {
            if (typeof opt === 'object') {
                optionsIds.push(opt.id);
                optionsMap.set(opt.id, opt);
            } else {
                optionsIds.push(opt);
                optionsMap.set(opt, { name: opt + '' });
            }
        });
    }

    const selectedOptionsIds = multiple ? (value && Array.isArray(value) ? value : []) : value;

    const renderOption = multiple
        ? (props, id, { selected }) => (
              <li {...props}>
                  <FormControlLabel
                      size="small"
                      checked={selected}
                      control={<Checkbox />}
                      label={optionsMap.get(id)?.name ?? ''}
                  />
              </li>
          )
        : null;

    function handleChange(event, value) {
        onChange({ target: { type: 'text', name, value } });
    }

    const restrictWidthClass = fullWidth ? undefined : classes.root;

    return (
        <Autocomplete
            PopperComponent={CustomPopper}
            fullWidth={fullWidth}
            className={restrictWidthClass}
            freeSolo={freeSolo}
            multiple={multiple}
            loading={loading}
            disabled={disabled || loading}
            value={selectedOptionsIds}
            options={optionsIds}
            onChange={handleChange}
            disableCloseOnSelect={multiple}
            getOptionLabel={(id) => optionsMap.get(id)?.name ?? ''}
            ChipProps={{ size: 'small' }}
            renderOption={renderOption}
            renderInput={(params) => {
                return (
                    <FormText
                        className={restrictWidthClass}
                        {...{
                            ...params,
                            required,
                            loading,
                            label,
                            inputProps: { ...params.inputProps, required: multiple ? value.length === 0 : required },
                            ...props,
                        }}
                    />
                );
            }}
        />
    );
}
