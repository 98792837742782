import React from 'react';

import { Link, useTheme } from '@mui/material';
import AttachmentIcon from '@mui/icons-material/Attachment';

import { humanFileSize } from '../lib/utils';

export function DownloadFileLink({ url, name, size = 0 }) {
    const theme = useTheme();

    if (!name) {
        return null;
    }

    let fileTitle = name + ' (' + humanFileSize(size) + ')';

    if (fileTitle) {
        fileTitle = (
            <Link download href={url}>
                {fileTitle}
            </Link>
        );
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <AttachmentIcon style={{ marginRight: theme.spacing(1) }} />
            {fileTitle}
        </div>
    );
}
