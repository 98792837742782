import React from 'react';

import { api } from '../../lib/api';
import { CustomDataTable, useCustomDataTable } from '../../components/CustomDataTable';
import { WoodmansForm } from './Form';

async function rowsLoader(params) {
    return (await api.woodmansList({ ...params }))?.rows;
}

async function rowsDeleter(ids, params) {
    return await api.woodmansDelete({ ...params, ids });
}

function WoodmansList() {
    const [editId, setEditId] = React.useState(false);

    const columns = [
        { field: 'photo', title: 'Фотография', type: 'image' },
        { field: 'name', title: 'Имя' },
        { field: 'forestry_name', title: 'Лесничество' },
    ];

    const { tableProps, tableActions } = useCustomDataTable({ columns, rowsLoader, rowsDeleter });

    return (
        <div>
            {false !== editId && (
                <WoodmansForm
                    objectId={editId}
                    onSave={() => {
                        setEditId(false);
                        tableActions.loadRows();
                    }}
                    onClose={() => setEditId(false)}
                />
            )}
            <CustomDataTable {...tableProps} onCreate={() => setEditId(null)} onEdit={(id) => setEditId(id)} />
        </div>
    );
}

export { WoodmansList };
