import React from 'react';

import { SvgIcon } from '@mui/material';

export function ExcelIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6m1.8 18H14l-2-3.4l-2 3.4H8.2l2.9-4.5L8.2 11H10l2 3.4l2-3.4h1.8l-2.9 4.5l2.9 4.5M13 9V3.5L18.5 9H13z" />
        </SvgIcon>
    );
}
