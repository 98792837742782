import _ from 'lodash';

export class Validator {
    constructor(inputData) {
        this.errors = {};
        this.data = { ...inputData };
    }

    checkAll(rules) {
        for (let rule of rules) {
            let path,
                check,
                validate = false;

            if (Object.keys(rule).length === 1) {
                path = Object.keys(rule)[0];
                check = Object.values(rule)[0];
            } else {
                if (!rule.path) {
                    throw new Error("'path' key required");
                }
                path = rule.path;

                if (!rule.check) {
                    throw new Error("'check' key required");
                }
                check = rule.check;

                validate = !!rule.validate;
            }

            this.check(path, check, validate);
        }

        return this;
    }

    check(path, checks, validateOnly) {
        checks = checks.split(/\s*,\s*/);
        let value = _.get(this.data, path);
        let error = this.checkForError(value, checks);
        if (error) {
            if (!validateOnly) {
                this.errors[path] = error;
            }
            return false;
        }
        return true;
    }

    validate(path, checks) {
        return this.check(path, checks, true);
    }

    checkForError(value, checks) {
        let error = null;
        checkloop: for (let check of checks) {
            switch (check) {
                case 'required':
                    if (!value || (value.length && value.length === 0)) {
                        error = 'Обязательно для заполнения';
                        break checkloop;
                    }
                    break;
                case 'number':
                    if (value?.length) {
                        if (isNaN(parseFloat(value))) {
                            error = 'Неверное число';
                            break checkloop;
                        }
                    }
                    break;
                case 'date':
                    if (value) {
                        let date = new Date(value);
                        if (date.toString() === 'Invalid Date') {
                            error = 'Неверная дата';
                            break checkloop;
                        }
                    }
                    break;
                case 'email':
                    if (value?.length) {
                        if (!value.match(/^\w+[\w-]*(\.\w[\w-]*)*@\w[\w-]*\.(\w[\w-]*\.)*\w{2,}$/su)) {
                            error = 'Неверный e-mail';
                            break checkloop;
                        }
                    }
                    break;
                default:
                    throw new Error('Unknown rule name: ' + check);
            }
        }
        return error;
    }

    setError(path, message) {
        this.errors[path] = message;
    }

    getErrors() {
        return this.errors;
    }

    isHasErrors() {
        return Object.keys(this.errors).length > 0;
    }
}

export function humanFileSize(size) {
    let postfix = ['байт', 'Кб', 'Мб', 'Гб', 'Тб', 'Пб', 'Еб', 'Зб', 'Йб'];

    let i = 0;
    while (size >= 1024) {
        size /= 1024;
        if (typeof postfix[i] == 'undefined') {
            break;
        }
        i++;
    }

    return size.toFixed(i ? 2 : 0) + ' ' + postfix[i];
}

export function formatNumber(number, precision = 2) {
    if (isNaN(+number)) {
        return null;
    }
    return (+number).toLocaleString('ru', { minimumFractionDigits: precision, maximumFractionDigits: precision });
}

export function humanNumber(number, maxPrecision = 2) {
    if (isNaN(+number)) {
        return null;
    }
    return (+number).toLocaleString('ru', { maximumFractionDigits: maxPrecision });
}

export function toArrayWithKeys(data) {
    let newList = [];

    if (Array.isArray(data)) {
        newList = [...data];
    }

    return newList.map((c) => {
        c.keyId = c.keyId ?? c.id ?? Math.random();
        return c;
    });
}

export function numberOrZero(num) {
    return isNaN(+num) ? 0 : +num;
}
