import React from 'react';

import _ from 'lodash';

import { useSearchParams } from 'react-router-dom';
import { messagesStore } from '../../../stores/messagesStore';
import { useMountedState } from '../../../hooks/useMountedState';

import LZString from 'lz-string';

function getRequestParams({ page, rowsPerPage, sortBy, sortDir, filterData }) {
    return {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        order: sortBy,
        orderDir: sortBy ? sortDir : null,
        filterData,
    };
}

export function decodeJsonFromURIComponent(encoded) {
    let decoded = null;

    if (encoded) {
        try {
            decoded = JSON.parse(LZString.decompressFromEncodedURIComponent(encoded));
        } catch (e) {}
    }

    return decoded;
}

export function encodeJsonToURIComponent(decoded) {
    return LZString.compressToEncodedURIComponent(JSON.stringify(decoded));
}

export function useDataTable(customConfig = {}) {
    const init = {
        loading: false,
        columns: [],
        columnsStorageKey: null,
        rowsLoader: null,
        rows: [],
        rowsCount: -1,
        selectedRows: [],
        page: 0,
        rowsPerPage: 5,
        sortBy: null,
        sortDir: null,
        deleteError: false,
        filterData: {},
        filterDataMiddleware: (data) => data,
        searchParams: [],
        filterOpen: false,
        ...customConfig,
    };

    const rowsLoader = init.rowsLoader;

    const columns = init.columns;
    const columnsStorageKey = init.columnsStorageKey;

    let columnsHiddenSaved = columnsStorageKey ? localStorage.getItem(columnsStorageKey) : undefined;

    if (!columnsHiddenSaved) {
        columnsHiddenSaved = [];
    } else {
        try {
            columnsHiddenSaved = JSON.parse(columnsHiddenSaved);
        } catch (e) {
            columnsHiddenSaved = [];
        }
    }

    const [columnsHidden, setColumnsHidden] = React.useState(columnsHiddenSaved);

    columnsHidden.forEach((hidden, i) => {
        if (columns && columns[i]) {
            columns[i].hidden = !!hidden;
        }
    });

    const [columnsOpen, setColumnsOpen] = React.useState(init.columnsOpen);
    const [loading, setLoading] = React.useState(init.loading);
    const [rows, setRows] = React.useState(init.rows);
    const [rowsCount, setRowsCount] = React.useState(init.rowsCount);
    const [selectedRows, setSelectedRows] = React.useState(init.selectedRows);
    const [deleteError, setDeleteError] = React.useState(init.deleteError);
    const [filterOpen, setFilterOpen] = React.useState(init.filterOpen);
    const [loadCount, setLoadCount] = React.useState(0);

    const [searchParams, setSearchParams] = useSearchParams(init.searchParams);

    const filterDataParam = decodeJsonFromURIComponent(searchParams.get('filterData'));
    const filterData = init.filterDataMiddleware(filterDataParam || init.filterData);
    const sortBy = searchParams.get('sortBy') || init.sortBy;
    const sortDir = searchParams.get('sortDir') || init.sortDir;
    const page = parseInt(searchParams.get('page') || init.page);
    const rowsPerPage = parseInt(searchParams.get('rowsPerPage') || init.rowsPerPage);

    const isMounted = useMountedState();

    function setFilterData(filterData) {
        if (null === filterData) {
            searchParams.delete('filterData');
        } else {
            searchParams.set('filterData', encodeJsonToURIComponent(filterData));
        }
        setSearchParams(searchParams);
    }

    function setSortBy(sortBy) {
        if (null === sortBy) {
            searchParams.delete('sortBy');
        } else {
            searchParams.set('sortBy', sortBy);
        }
        setSearchParams(searchParams);
    }

    function setSortDir(sortDir) {
        if (null === sortDir || sortDir === 'asc') {
            searchParams.delete('sortDir');
        } else {
            searchParams.set('sortDir', sortDir);
        }
        setSearchParams(searchParams);
    }

    function setPage(page) {
        searchParams.set('page', page);
        setSearchParams(searchParams);
    }

    function setRowsPerPage(rowsPerPage) {
        searchParams.set('rowsPerPage', rowsPerPage);
        setSearchParams(searchParams);
    }

    const onDelete = init.rowsDeleter
        ? (ids) => {
              if (ids.length) {
                  if (init.rowsDeleter) {
                      setLoading(true);
                      init.rowsDeleter(ids, tableParams)
                          .then((data) => {
                              if (data && isMounted()) {
                                  setRows(data.rows);
                                  setSelectedRows(data.deleteErrorIds);
                                  if (data.deleteErrorIds.length) {
                                      setDeleteError(true);
                                  }
                              }
                          })
                          .catch((e) => {
                              console.error(e);
                              messagesStore.setError500(true);
                          })
                          .finally(() => {
                              if (isMounted()) {
                                  setLoading(false);
                              }
                          });
                  }
              }
          }
        : undefined;

    function setFilteredRows(rows) {
        setPage(0);
        setRows(rows);
        setSelectedRows([]);
    }

    function applyFilterData(filterData) {
        setFilterData(_.cloneDeep(filterData));
        setPage(0);
        setSelectedRows([]);
        loadRows();
    }

    function loadRows() {
        setLoadCount((count) => {
            count++;
            if (count > 65535) {
                count = 1;
            }
            return count;
        });
    }

    function onSelectedRows(rowIds) {
        setSelectedRows(rowIds);
    }

    function onPage(newPage) {
        setPage(newPage);
    }

    function onRowsPerPage(newRowsPerPage) {
        setRowsPerPage(newRowsPerPage);
    }

    function onSortBy(newSortBy) {
        setSortBy(newSortBy);
    }

    function onSortDir(newSortDir) {
        setSortDir(newSortDir);
    }

    function onDeleteError(newDeleteError) {
        setDeleteError(newDeleteError);
    }

    function onFilterToggle(newFilterOpen) {
        setFilterOpen(newFilterOpen);
    }

    const onColumnsChange = columnsStorageKey
        ? (newColumns) => {
              const newColumnsHidden = newColumns.map((v) => !!v.hidden);
              localStorage.setItem(columnsStorageKey, JSON.stringify(newColumnsHidden));
              setColumnsHidden(newColumnsHidden);
          }
        : undefined;

    const onColumnsToggle = columnsStorageKey ? (newColumnsOpen) => setColumnsOpen(newColumnsOpen) : undefined;

    const tableProps = {
        loading,
        rows,
        rowsCount,
        selectedRows,
        onSelectedRows,
        page,
        onPage,
        rowsPerPage,
        onRowsPerPage,
        sortBy,
        onSortBy,
        sortDir,
        onSortDir,
        deleteError,
        onDeleteError,
        onDelete,
        filterData,
        filterOpen,
        onFilterToggle,
        columns,
        onColumnsChange,
        columnsOpen,
        onColumnsToggle,
    };

    const tableParams = getRequestParams({
        page,
        rowsPerPage,
        sortBy,
        sortDir,
        filterData,
    });

    const tableActions = {
        setRows,
        setRowsCount,
        setLoading,
        setSelectedRows,
        setPage,
        setRowsPerPage,
        setSortBy,
        setSortDir,
        setDeleteError,
        setFilterOpen,
        setFilterData,
        applyFilterData,
        setFilteredRows,
        setColumnsOpen,
        loadRows,
    };

    const filterDataString = JSON.stringify(tableProps.filterData);

    React.useEffect(() => {
        let loadTimer;
        let active = true;

        (async () => {
            if (rowsLoader) {
                loadTimer = setTimeout(() => {
                    if (active) {
                        setLoading(true);
                    }
                }, 500);

                try {
                    let rows = await rowsLoader(
                        getRequestParams({
                            page: tableProps.page,
                            rowsPerPage: tableProps.rowsPerPage,
                            sortBy: tableProps.sortBy,
                            sortDir: tableProps.sortDir,
                            filterData: JSON.parse(filterDataString),
                        })
                    );

                    if (active && rows) {
                        setRows(rows);
                    }
                } catch (e) {
                    console.error(e);
                    messagesStore.setError500(true);
                } finally {
                    clearTimeout(loadTimer);
                    if (active) {
                        setLoading(false);
                    }
                }
            }
        })();

        return () => {
            active = false;
            clearTimeout(loadTimer);
        };
    }, [
        loadCount,
        rowsLoader,
        tableProps.page,
        tableProps.rowsPerPage,
        tableProps.sortBy,
        tableProps.sortDir,
        filterDataString,
    ]);

    return {
        tableProps,
        tableParams,
        tableActions,
    };
}
