import React from 'react';

import { useTheme } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

export function SelectFirstOptionAdd() {
    const theme = useTheme();

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <AddCircleIcon />
            <div
                style={{
                    marginLeft: theme.spacing(1),
                }}
            >
                Добавить
            </div>
        </div>
    );
}
