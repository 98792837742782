import React from 'react';

import { FormText } from './FormText';

function FormNumber(props) {
    return (
        <FormText
            type="number"
            onWheel={(e) => e.target.blur()}
            {...{ inputProps: { min: 0, ...props.inputProps }, ...props }}
        />
    );
}

export { FormNumber };
