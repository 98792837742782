import React from 'react';

import { Table, TableBody, TableCell, TableContainer, TableRow, useTheme } from '@mui/material';

import { dictionariesStore } from '../../../stores/dictionariesStore';
import { observer } from 'mobx-react-lite';

import { FormNumber } from '../../../components/common/FormNumber';
import { HeaderDivider } from '../../../components/HeaderDivider';
import { StructuresSelect } from '../../../components/StructuresSelect';
import { SelectFirstOptionAdd } from '../../../components/SelectFirstOptionAdd';
import { DeleteButtonWithConfirmation } from '../../../components/DeleteButtonWithConfirmation';

import { useFormStyles } from '../../../hooks/useFormStyles';

function StructureFields({ prefix, fieldProps, structure }) {
    const classes = useFormStyles();

    return (
        <React.Fragment>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div>
                    {structure?.name} ({structure?.abbr})
                </div>
            </div>
            <div className={classes.fieldsGroup}>
                <div className={classes.fieldsGroup}>
                    <FormNumber {...fieldProps(prefix + 'height')} label="Высота" />
                    <FormNumber {...fieldProps(prefix + 'diameter')} label="Диаметр" />
                </div>
                <div className={classes.fieldsGroup}>
                    <FormNumber {...fieldProps(prefix + 'market_class')} label="Класс товарности" />
                    <FormNumber {...fieldProps(prefix + 'reserve_wet')} label="Корневой запас, м³" />
                </div>
            </div>
        </React.Fragment>
    );
}

const StructureItems = observer(({ structures, loading, onRemove = () => null, fieldPrefix, fieldProps }) => {
    const structuresDict = dictionariesStore.structures;

    function getStructureById(id) {
        const found = structuresDict.find((v) => v.id === id);
        if (found) {
            return found;
        }
    }

    if (!structures.length) {
        return null;
    }

    return (
        <TableContainer>
            <Table>
                <TableBody>
                    {structures.map((structure, index) => {
                        let prefix = fieldPrefix + index + '.';

                        return (
                            <TableRow key={structure.keyId}>
                                <TableCell>
                                    <StructureFields
                                        prefix={prefix}
                                        fieldProps={fieldProps}
                                        structure={getStructureById(structure.structure_id)}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <DeleteButtonWithConfirmation data={index} disabled={loading} onDelete={onRemove} />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
});

function StructuresList({ structures, fieldPrefix = 'structures.', fieldProps, loading, onAdd, onRemove }) {
    const theme = useTheme();

    return (
        <React.Fragment>
            <HeaderDivider>
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                    }}
                >
                    <div style={{ marginRight: theme.spacing(1) }}>Порода ({structures.length})</div>
                    <div>
                        <StructuresSelect
                            value=""
                            label=""
                            loading={loading}
                            firstOptionText={<SelectFirstOptionAdd />}
                            SelectProps={{ displayEmpty: true }}
                            size="small"
                            onChange={onAdd}
                        />
                    </div>
                </div>
            </HeaderDivider>
            <StructureItems
                structures={structures}
                loading={loading}
                onRemove={onRemove}
                fieldPrefix={fieldPrefix}
                fieldProps={fieldProps}
            />
        </React.Fragment>
    );
}

export { StructuresList };
