import { makeAutoObservable } from 'mobx';

class AuthStore {
    constructor() {
        let data = localStorage.getItem('auth:data');
        if (data) {
            data = JSON.parse(data);
        }
        this.id = data ? data.id : null;
        this.key = data ? data.key : null;
        makeAutoObservable(this);
    }

    setAuthorized(data) {
        this.id = data.id;
        this.key = data.key;
        localStorage.setItem('auth:data', JSON.stringify(data));
    }

    refreshAuthorization() {
        if (this.authTimer) {
            clearTimeout(this.authTimer);
            this.authTimer = null;
        }

        function deauthorize() {
            if (this.isAuthorized) {
                this.setUnauthorized();
            }
        }

        // Без каких-либо действий на сервере - прекращаем авторизацию через 30 мин
        this.authTimer = setTimeout(deauthorize.bind(this), 1000 * 60 * 29.5);
    }

    setUnauthorized() {
        this.id = null;
        this.key = null;
        localStorage.removeItem('auth:data');
    }

    get isAuthorized() {
        return null !== this.key;
    }
}

const authStore = new AuthStore();

export { authStore };
