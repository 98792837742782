import React from 'react';

import { makeStyles } from '@mui/styles';
import { Box, Container, Paper } from '@mui/material';

import { Copyright } from '../../components/Copyright';
import { LoginDialog } from './LoginDialog';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        height: '100vh',
    },
}));

function LoginScreen() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Container maxWidth="xs">
                <Paper>
                    <Box p={2}>
                        <LoginDialog />
                    </Box>
                </Paper>
                <Box mt={8} style={{ textAlign: 'center' }}>
                    <Copyright />
                </Box>
            </Container>
        </div>
    );
}

export { LoginScreen };
