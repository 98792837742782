import React from 'react';

import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { FormLabel, Typography, useTheme } from '@mui/material';

import { api } from '../../../lib/api';
import { useMountedState } from '../../../hooks/useMountedState';
import { messagesStore } from '../../../stores/messagesStore';
import { humanNumber, numberOrZero, toArrayWithKeys, Validator } from '../../../lib/utils';
import { dictionariesStore } from '../../../stores/dictionariesStore';
import { AllotmentCategoriesRadio } from '../../../components/AllotmentCategoriesRadio';
import { FarmsSelect } from '../../../components/FarmsSelect';
import { FellingKindsSelect } from '../../../components/FellingKindsSelect';
import { ForestriesSelect } from '../../../components/ForestriesSelect';
import { FormCheckbox } from '../../../components/common/FormCheckbox';
import { FormDate } from '../../../components/common/FormDate';
import { FormFileListUpload } from '../../../components/common/FormFilesUpload';
import { FormGps, FormGpsFormatSwitch } from '../../../components/common/FormGps';
import { FormNumber } from '../../../components/common/FormNumber';
import { FormOneImageUpload } from '../../../components/common/FormOneImageUpload';
import { HeaderDivider } from '../../../components/HeaderDivider';
import { StructuresSelect } from '../../../components/StructuresSelect';
import { AllotmentStructures } from './AllotmentStructures';
import { CoordsList } from './CoordsList';
import { GpsCoordsList } from './GpsCoordsList';
import { FormText } from '../../../components/common/FormText';
import {
    enumerateCoords,
    enumerateGps,
    gpsListConvert,
    normalizeDataFromBackend,
    normalizeDataToBackend,
    storageGpsConvert,
} from '../utils';
import { ALLOTMENT_CATEGORY_PROTECTED, FELLING_FORM_SELECTION_ID } from '../../../constants/dictionaries';
import { DataForm } from '../../../components/common/DataForm';

import { useFormStyles } from '../../../hooks/useFormStyles';
import { SchemaScaleSelect } from '../../../components/SchemaScaleSelect';

function Summary({ label, value }) {
    const theme = useTheme();
    return (
        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
            <FormLabel>{label}:</FormLabel>
            <Typography variant="body1" style={{ marginLeft: theme.spacing(1) }}>
                {value}
            </Typography>
        </div>
    );
}

export const EditContent = observer(
    ({
        objectId,
        onClose,
        onSave,
        data,
        setData,
        setErrors,
        loading,
        setLoading,
        setProcessing,
        formProps,
        fieldProps,
        filesUploadProps,
    }) => {
        const classes = useFormStyles();

        const isMounted = useMountedState();

        const fellingKinds = dictionariesStore.felling_kinds;
        const fellingKind = fellingKinds.find((kind) => +kind.id === +data?.felling_kind_id);

        function handleOpen() {
            if (objectId) {
                setLoading(true);
                api.cutoutsGet(objectId)
                    .then((data) => {
                        if (data && isMounted()) {
                            setData(normalizeDataFromBackend(data.data));
                        }
                    })
                    .finally(() => {
                        if (isMounted()) {
                            setLoading(false);
                        }
                    });
            }
        }

        function handleSave() {
            const validator = new Validator(data).checkAll([
                { number: 'required' },
                { created_on: 'required, date' },
                { forestry_id: 'required' },
                { quarter: 'required' },
                { farm_id: 'required' },
                { felling_kind_id: 'required' },
                { category_id: 'required' },
                { schema: 'required' },
            ]);

            if (fellingKind?.form_id === FELLING_FORM_SELECTION_ID) {
                validator.check('selection_percent', 'required');
            }

            // if (data?.allotments?.length > 0) {
            //     for (let ai in data.allotments) {
            //         if (data.allotments[ai]?.structures?.length > 0) {
            //             for (let si in data.allotments[ai].structures) {
            //                 validator.checkAll([
            //                     { ['allotments.' + ai + '.structures.' + si + '.reserve_taxation']: 'required' },
            //                 ]);
            //             }
            //         }
            //     }
            // }

            if (data?.coords?.length > 0) {
                for (let i in data.coords) {
                    validator.checkAll([
                        { ['coords.' + i + '.number']: 'required' },
                        { ['coords.' + i + '.rumba']: 'required' },
                        { ['coords.' + i + '.length']: 'required' },
                    ]);
                }
            }

            if (data?.gps?.length > 0) {
                for (let i in data.gps) {
                    validator.checkAll([
                        { ['gps.' + i + '.latitude']: 'required' },
                        { ['gps.' + i + '.longitude']: 'required' },
                    ]);
                }
            }

            setErrors(validator.getErrors());

            if (!validator.isHasErrors()) {
                setProcessing(true);

                let sendData = normalizeDataToBackend(data);

                (objectId ? api.cutoutsEdit(objectId, sendData) : api.cutoutsCreate(sendData))
                    .then((result) => {
                        if (isMounted()) {
                            if (result) {
                                if (result.errors) {
                                    setErrors(result.errors);
                                } else {
                                    messagesStore.setSuccessSaved(true);
                                    onSave();
                                }
                            }
                        }
                    })
                    .finally(() => {
                        if (isMounted()) {
                            setProcessing(false);
                        }
                    });
            }
        }

        /**
         * Выделы с составами леса (породы)
         */
        const dataAllotments = toArrayWithKeys(data?.allotments ? data?.allotments : []).map((allotment) => {
            allotment.structures = toArrayWithKeys(allotment?.structures?.length ? allotment.structures : []);
            return allotment;
        });

        function handleRemoveAllotment(index) {
            const newData = _.cloneDeep(data);
            _.pullAt(dataAllotments, [index]);
            newData.allotments = _.cloneDeep(dataAllotments);
            setData(newData);
        }

        function handleAddStructure(allotmentIndex, structure_id) {
            const newData = _.cloneDeep(data);
            dataAllotments[allotmentIndex].structures.push({ structure_id, keyId: Math.random(), reserve_taxation: 0 });
            newData.allotments = _.cloneDeep(dataAllotments);
            setData(newData);
        }

        function handleRemoveStructure(allotmentIndex, structureIndex) {
            const newData = _.cloneDeep(data);
            _.pullAt(dataAllotments[allotmentIndex].structures, [structureIndex]);
            newData.allotments = _.cloneDeep(dataAllotments);
            setData(newData);
        }

        /**
         * Координаты общее
         */
        function getLastAdded(coords, type) {
            let lastBeforeAdded = null;
            let lastAdded = null;

            if (coords.length) {
                coords.forEach((coord) => {
                    if (+coord.type === +type) {
                        if (lastAdded) {
                            lastBeforeAdded = lastAdded;
                        }
                        lastAdded = coord;
                    }
                });
            }

            return [lastBeforeAdded, lastAdded];
        }

        /**
         * Координаты в румбах (устаревшие)
         */
        let coords = toArrayWithKeys(data.coords);

        function handleAddCoord(e) {
            const type = e.target.value;
            const newData = _.cloneDeep(data);
            coords.push({ type, keyId: Math.random() });
            newData.coords = enumerateCoords(_.cloneDeep(coords));

            const [, lastAdded] = getLastAdded(newData.coords, type);

            if (lastAdded) {
                lastAdded.autoFocus = true;
            }

            setData(newData);
        }

        function handleRemoveCoord(index) {
            const newData = _.cloneDeep(data);
            _.pullAt(coords, [index]);
            newData.coords = enumerateCoords(_.cloneDeep(coords));
            setData(newData);
        }

        /**
         * Координаты в GPS
         */
        let gps = toArrayWithKeys(data.gps);

        function handleGpsFormatChange(format) {
            setData((oldData) => {
                const newData = { ...oldData, gps_format: format };
                gpsListConvert(newData?.gps, oldData.gps_format, newData.gps_format);
                return newData;
            });
        }

        function handleAddGps(e) {
            const type = e.target.value;
            const newData = _.cloneDeep(data);
            gps.push({ type, keyId: Math.random() });
            newData.gps = enumerateGps(_.cloneDeep(gps));

            const [lastBeforeAdded, lastAdded] = getLastAdded(newData.gps, type);

            if (lastBeforeAdded && lastBeforeAdded.longitude) {
                lastAdded.longitude = lastBeforeAdded.longitude.replace(/^(\d{2}.\d{2}).*$/, '$1');
            }

            if (lastBeforeAdded && lastBeforeAdded.latitude) {
                lastAdded.latitude = lastBeforeAdded.latitude.replace(/^(\d{2}.\d{2}).*$/, '$1');
            }

            if (lastAdded) {
                lastAdded.autoFocus = true;
            }

            setData(newData);
        }

        function handleRemoveGps(index) {
            const newData = _.cloneDeep(data);
            _.pullAt(gps, [index]);
            newData.gps = enumerateGps(_.cloneDeep(gps));
            setData(newData);
        }

        function handleStorageGpsFormatChange(format) {
            setData((oldData) => {
                const newData = { ...oldData, storage_gps_format: format };
                storageGpsConvert(newData, oldData.storage_gps_format, newData.storage_gps_format);
                return newData;
            });
        }

        const summarySquare = dataAllotments.reduce((sum, atm) => sum + numberOrZero(atm.selection_square), 0);

        const summaryTaxation = dataAllotments.reduce(
            (sum, atm) => sum + atm.structures.reduce((sum, s) => sum + numberOrZero(s['reserve_taxation']), 0),
            0
        );

        const summaryReserve = new Map([
            ['wet', 0],
            ['big', 0],
            ['medium', 0],
            ['small', 0],
            ['dead', 0],
        ]);

        for (let key of summaryReserve.keys()) {
            let sumKey = 'reserve_' + key;
            summaryReserve.set(
                key,
                Math.round(
                    dataAllotments.reduce(
                        (sum, atm) =>
                            sum +
                            atm.structures.reduce((sum, s) => {
                                let value = numberOrZero(s[sumKey]);
                                if (s.is_dead && key !== 'wet' && key !== 'dead') {
                                    value = 0;
                                }
                                return sum + value;
                            }, 0),
                        0
                    )
                )
            );
        }

        const summarySelection = Math.round((summaryTaxation * data.selection_percent) / 100);

        return (
            <DataForm
                {...formProps()}
                editing={!!objectId}
                onOpen={handleOpen}
                onSave={handleSave}
                onClose={onClose}
                fullScreenDialog
            >
                <div className={classes.fieldsGroup}>
                    <FormNumber {...fieldProps('number')} label="Лесосека" required autoFocus />
                    <FormDate {...fieldProps('created_on')} label="Дата" required />
                </div>
                <div className={classes.fieldsGroup}>
                    <ForestriesSelect {...fieldProps('forestry_id')} required />
                </div>
                <div className={classes.fieldsGroup}>
                    <div className={classes.fieldsGroup}>
                        <FormNumber {...fieldProps('quarter')} label="Квартал" required />
                        <FarmsSelect {...fieldProps('farm_id')} required />
                    </div>
                    <div className={classes.fieldsGroup}>
                        <FellingKindsSelect {...fieldProps('felling_kind_id')} required />
                        {+fellingKind?.form_id === FELLING_FORM_SELECTION_ID ? (
                            <FormNumber {...fieldProps('selection_percent')} label="Процент выборки" required />
                        ) : null}
                    </div>
                </div>
                <div className={classes.fieldsGroup}>
                    <FormText {...fieldProps('contractor')} label="Исполнитель" />
                    <FormText {...fieldProps('comments')} multiline fullWidth label="Комментарии" />
                </div>
                <div className={classes.fieldsGroup}>
                    <div className={classes.fieldsGroup}>
                        <AllotmentCategoriesRadio {...fieldProps('category_id')} required />
                        {+data.category_id === ALLOTMENT_CATEGORY_PROTECTED ? (
                            <FormText
                                multiline
                                fullWidth
                                {...fieldProps('category_comments')}
                                label="Категория защитности"
                            />
                        ) : null}
                    </div>
                    <div className={classes.fieldsGroup} style={{ flexDirection: 'column' }}>
                        <FormOneImageUpload {...fieldProps('schema')} label="Схема" />
                        <SchemaScaleSelect {...fieldProps('schema_scale')} />
                    </div>
                </div>
                <div className={classes.fieldsGroup}>
                    <FormText {...fieldProps('activities')} fullWidth label="Хозяйственные мероприятия" />
                    <FormText {...fieldProps('fsc_requirements')} fullWidth label="Требования FSC" />
                </div>
                <HeaderDivider>Суммарно по лесосеке</HeaderDivider>
                <div className={classes.fieldsGroup}>
                    <Summary label="Площадь (га)" value={humanNumber(summarySquare)} />
                    <Summary label="Таксационный запас, м³" value={humanNumber(summaryTaxation)} />
                    <Summary label="Выборка, м³" value={humanNumber(summarySelection)} />
                    <Summary label="Вырубаемый объём, м³" value={humanNumber(summaryReserve.get('wet'))} />
                    <Summary label="Крупная, м³" value={humanNumber(summaryReserve.get('big'))} />
                    <Summary label="Средняя, м³" value={humanNumber(summaryReserve.get('medium'))} />
                    <Summary label="Мелкая, м³" value={humanNumber(summaryReserve.get('small'))} />
                    <Summary label="Дровяной, м³" value={humanNumber(summaryReserve.get('dead'))} />
                </div>
                {dataAllotments.map((atm, index) => (
                    <AllotmentStructures
                        key={atm.keyId}
                        allotment={atm}
                        allotmentIndex={index}
                        fieldProps={fieldProps}
                        loading={loading}
                        onAdd={handleAddStructure}
                        onRemove={handleRemoveStructure}
                        onRemoveAllotment={handleRemoveAllotment}
                    />
                ))}
                <HeaderDivider>Подрост</HeaderDivider>
                <div className={classes.fieldsGroup}>
                    <div className={classes.fieldsGroup}>
                        <FormNumber {...fieldProps('undergrowth_count')} label="Количество" />
                        <FormNumber {...fieldProps('undergrowth_height')} label="Высота" />
                    </div>
                    <div className={classes.fieldsGroup}>
                        <FormNumber {...fieldProps('undergrowth_age')} label="Возраст" />
                        <StructuresSelect {...fieldProps('undergrowth_structure_id')} />
                    </div>
                </div>
                <div>
                    <CoordsList
                        coords={coords}
                        fieldProps={fieldProps}
                        loading={loading}
                        onAdd={handleAddCoord}
                        onRemove={handleRemoveCoord}
                    />
                    <GpsCoordsList
                        coords={gps}
                        inputFormat={data.gps_format}
                        fieldProps={fieldProps}
                        loading={loading}
                        onAdd={handleAddGps}
                        onFormatChange={handleGpsFormatChange}
                        onRemove={handleRemoveGps}
                    />
                </div>
                <HeaderDivider>Состояние лесосеки</HeaderDivider>
                <div>
                    <FormCheckbox {...fieldProps('is_road_needs')} label="Требуется прокладка дороги" />
                </div>
                {data.is_road_needs ? (
                    <div className={classes.fieldsGroup}>
                        <FormText
                            {...fieldProps('road_comments')}
                            multiline
                            fullWidth
                            label="Протяженность, материалы, техника"
                        />
                        <FormText {...fieldProps('fsc_road_requirements')} fullWidth label="Требования FSC к дорогам" />
                        <FormFileListUpload {...filesUploadProps('files.road')} />
                    </div>
                ) : null}
                <div className={classes.fieldsGroup}>
                    <FormCheckbox {...fieldProps('is_thinning_needs')} label="Требуется прореживание" />
                </div>
                {data.is_thinning_needs ? (
                    <div className={classes.fieldsGroup}>
                        <FormText {...fieldProps('thinning_comments')} multiline fullWidth label="Комментарии" />
                        <FormFileListUpload {...filesUploadProps('files.thinning')} />
                    </div>
                ) : null}
                <div className={classes.fieldsGroup}>
                    <FormLabel>Место складирования</FormLabel>
                </div>
                <div className={classes.fieldsGroup}>
                    <FormGps
                        {...fieldProps('storage_latitude')}
                        angleFormat="latitude"
                        inputFormat={data.storage_gps_format}
                        label="Широта (X)"
                    />
                    <FormGps
                        {...fieldProps('storage_longitude')}
                        angleFormat="longitude"
                        inputFormat={data.storage_gps_format}
                        label="Долгота (Y)"
                    />
                    <FormGpsFormatSwitch
                        inputFormat={data.storage_gps_format}
                        onChange={handleStorageGpsFormatChange}
                    />
                </div>
                <div>
                    <FormText
                        {...fieldProps('storage_comments')}
                        multiline
                        fullWidth
                        label="Собственник, название склада"
                    />
                    <FormFileListUpload {...filesUploadProps('files.storage')} />
                </div>
            </DataForm>
        );
    }
);
