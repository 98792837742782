import React from 'react';

import { Table, TableBody, TableCell, TableContainer, TableRow, useTheme } from '@mui/material';

import { dictionariesStore } from '../../../stores/dictionariesStore';
import { observer } from 'mobx-react-lite';

import { FormNumber } from '../../../components/common/FormNumber';
import { HeaderDivider } from '../../../components/HeaderDivider';
import { StructuresSelect } from '../../../components/StructuresSelect';
import { FormCheckbox } from '../../../components/common/FormCheckbox';
import { SelectFirstOptionAdd } from '../../../components/SelectFirstOptionAdd';
import { DeleteButtonWithConfirmation } from '../../../components/DeleteButtonWithConfirmation';
import { humanNumber } from '../../../lib/utils';

import { useFormStyles } from '../../../hooks/useFormStyles';

function StructureFields({ fieldPrefix, fieldProps, structure }) {
    const classes = useFormStyles();

    function fieldName(name) {
        return fieldPrefix + name;
    }

    let { value: isDead } = fieldProps(fieldName('is_dead'));

    return (
        <React.Fragment>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div>
                    {structure?.name} ({structure?.abbr})
                </div>
            </div>
            <div className={classes.fieldsGroup}>
                <div className={classes.fieldsGroup}>
                    <FormNumber
                        {...fieldProps(fieldName('reserve_taxation'))}
                        label="Таксационный запас, м³"
                        inputProps={{ readOnly: true, style: { backgroundColor: '#eef' } }}
                    />
                    <FormNumber
                        {...fieldProps(fieldName('reserve_wet'))}
                        label="Вырубаемый объем, м³"
                        inputProps={{ readOnly: true }}
                    />
                </div>
                <div className={classes.fieldsGroup}>
                    <FormCheckbox {...fieldProps(fieldName('is_dead'))} label="Сухостой" />
                </div>
                <div className={classes.fieldsGroup}>
                    {!isDead ? (
                        <div className={classes.fieldsGroup}>
                            <FormNumber {...fieldProps(fieldName('reserve_big'))} label="Крупная, м³" />
                            <FormNumber {...fieldProps(fieldName('reserve_medium'))} label="Средняя, м³" />
                        </div>
                    ) : null}
                    <div className={classes.fieldsGroup}>
                        {!isDead ? <FormNumber {...fieldProps(fieldName('reserve_small'))} label="Мелкая, м³" /> : null}
                        <FormNumber {...fieldProps(fieldName('reserve_dead'))} label="Дровяной, м³" />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const StructureItems = observer(({ structures, loading, onRemove = () => null, fieldsPrefix, fieldProps }) => {
    const structuresDict = dictionariesStore.structures;

    function getStructureById(id) {
        const found = structuresDict.find((v) => v.id === id);
        if (found) {
            return found;
        }
    }

    if (!structures.length) {
        return null;
    }

    return (
        <TableContainer>
            <Table>
                <TableBody>
                    {structures.map((structure, index) => {
                        let fieldPrefix = fieldsPrefix + index + '.';

                        return (
                            <TableRow key={structure.keyId}>
                                <TableCell>
                                    <StructureFields
                                        fieldPrefix={fieldPrefix}
                                        fieldProps={fieldProps}
                                        structure={getStructureById(structure.structure_id)}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <DeleteButtonWithConfirmation data={index} disabled={loading} onDelete={onRemove} />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
});

export function AllotmentStructures({
    allotment,
    allotmentIndex,
    fieldsPrefix = 'allotments.',
    fieldProps,
    loading,
    onAdd = () => null,
    onRemove = () => null,
    onRemoveAllotment = () => null,
}) {
    const theme = useTheme();

    const structures = allotment.structures;

    function handleAddStructure(e) {
        const structure_id = e.target.value;
        if (structure_id) {
            onAdd(allotmentIndex, structure_id);
        }
    }

    function handleRemoveStructure(structureIndex) {
        onRemove(allotmentIndex, structureIndex);
    }

    return (
        <React.Fragment>
            <HeaderDivider>
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                    }}
                >
                    <div style={{ marginRight: theme.spacing(1) }}>
                        Породы выдела №{allotment.allotment_number} [{humanNumber(allotment.selection_square)} га] (
                        {structures.length})
                    </div>
                    <div style={{ marginRight: theme.spacing(1) }}>
                        <StructuresSelect
                            value=""
                            label=""
                            loading={loading}
                            firstOptionText={<SelectFirstOptionAdd />}
                            SelectProps={{ displayEmpty: true }}
                            size="small"
                            onChange={handleAddStructure}
                        />
                    </div>
                    <div style={{ marginRight: theme.spacing(1) }}>
                        <DeleteButtonWithConfirmation
                            tooltipText="Удалить выдел"
                            text="Вы уверены что хотите удалить выдел со всеми породами?"
                            data={allotmentIndex}
                            disabled={loading}
                            onDelete={onRemoveAllotment}
                        />
                    </div>
                </div>
            </HeaderDivider>
            <StructureItems
                structures={structures}
                loading={loading}
                onRemove={handleRemoveStructure}
                fieldsPrefix={fieldsPrefix + allotmentIndex + '.structures.'}
                fieldProps={fieldProps}
            />
        </React.Fragment>
    );
}
