import React from 'react';

import { TextField } from '@mui/material';

function FormText({ loading, disabled, error, helperText, ...props }) {
    let errorText = '';

    if (error && error !== true) {
        errorText = error;
        error = true;
    }

    helperText = errorText + (errorText && helperText ? ', ' : '') + (helperText ? helperText : '');

    return <TextField disabled={disabled || loading} {...props} error={error} helperText={helperText} />;
}

export { FormText };
