import React from 'react';

import { observer } from 'mobx-react-lite';

import { makeStyles } from '@mui/styles';
import { Collapse, TableCell, TableRow } from '@mui/material';

import { dictionariesStore } from '../../../stores/dictionariesStore';
import { CustomDataTable } from '../../../components/CustomDataTable';

const useStyles = makeStyles((theme) => ({
    cells: {
        paddingBottom: 0,
        paddingTop: 0,
        borderBottom: 'unset',
    },
}));

const StructuresList = observer(({ row, colspan, show }) => {
    const structures = dictionariesStore.structures;
    const classes = useStyles();

    function getStructureNameById(id) {
        const found = structures.find((s) => +s.id === +id);
        if (!found) {
            return '';
        }
        return `${found.name} (${found.abbr})`;
    }

    const columns = [
        {
            field: 'structure_id',
            title: 'Порода',
            renderValue: ({ formattedValue }) => getStructureNameById(formattedValue),
        },
        { field: 'height', title: 'Высота', type: 'integer' },
        { field: 'diameter', title: 'Диаметр', type: 'integer' },
        {
            field: 'reserve_wet',
            title: 'Корневой запас, м³',
            type: 'integer',
        },
        { field: 'market_class', title: 'Класс товарности', type: 'integer' },
    ];

    const rows = row.structures;

    const [sortBy, setSortBy] = React.useState(null);
    const [sortDir, setSortDir] = React.useState(null);

    const sortedRows = rows.sort((a, b) => {
        let sort = 0;

        if (sortBy) {
            if (sortBy === 'structure_id') {
                sort = getStructureNameById(a[sortBy]).localeCompare(getStructureNameById(b[sortBy]));
            } else {
                sort = a[sortBy] - b[sortBy];
            }
        }

        if (sort === 0) {
            sort = a.id - b.id;
        }

        if (sortDir === 'desc') {
            sort *= -1;
        }

        return sort;
    });

    return (
        <TableRow>
            <TableCell colSpan={colspan + 2} className={classes.cells}>
                <Collapse in={show} unmountOnExit>
                    <CustomDataTable
                        columns={columns}
                        rows={sortedRows}
                        inlineTable
                        maximizeWidth={false}
                        sortBy={sortBy}
                        sortDir={sortDir}
                        onSortBy={setSortBy}
                        onSortDir={setSortDir}
                    />
                </Collapse>
            </TableCell>
        </TableRow>
    );
});

export { StructuresList };
