import React from 'react';

import { useDataForm } from '../../components/common/DataForm';
import { EditContent } from './Form/EditContent';
import { recalculateStructure } from './utils';
import { SelectAllotments } from './Form/SelectAllotments';
import { api } from '../../lib/api';
import { useMountedState } from '../../hooks/useMountedState';

export function CutoutsForm({ objectId, onClose = () => null, onSave = () => null }) {
    const isMounted = useMountedState();

    const [allotmentsSelected, setAllotmentsSelected] = React.useState(false);

    const dataFormHookProps = useDataForm({
        initialData: { created_on: new Date() },
        handleChangeMiddleware: ({ name, newData }) => {
            let matches = name.match(/^allotments\.(\d+)\.structures\.(\d+)\.(\w+)/);
            if (matches) {
                const aIndex = matches[1];
                const sIndex = matches[2];
                const changedField = matches[3];
                newData.allotments[aIndex].structures[sIndex] = recalculateStructure(
                    newData.allotments[aIndex].structures[sIndex],
                    changedField
                );
            }
            return { newData };
        },
    });

    const { setLoading, setData } = dataFormHookProps;

    function handleCreateCutout(data) {
        setLoading(true);

        api.cutoutsNextNumber(data.forestry_id, data.quarter)
            .then((number) => {
                if (number && isMounted()) {
                    let newData = {
                        ...data.allotments[0],
                        number,
                        created_on: new Date(),
                        forestry_id: data.forestry_id,
                        quarter: data.quarter,
                        farm_id: data.farm_id,
                        category_id: data.category_id,
                        category_comments: data.category_comments,
                        felling_kind_id: data.felling_kind_id,
                        selection_percent: data.selection_percent,
                    };

                    delete newData.structures;

                    newData.allotments = data.allotments.map((atm) => {
                        const allotment = {
                            allotment_id: atm.id,
                            allotment_number: atm.number,
                            selection_square: atm.selection_square,
                            structures: [],
                        };

                        allotment.structures = atm.structures.map((s) => {
                            const reserve_wet = Math.round(s.reserve_wet * (atm.selection_square / atm.square));
                            const structure = {
                                structure_id: s.structure_id,
                                reserve_wet: reserve_wet,
                                reserve_taxation: reserve_wet,
                                is_dead: false,
                                reserve_big: null,
                                reserve_medium: null,
                                reserve_small: null,
                                reserve_dead: null,
                            };

                            return structure;
                        });

                        return allotment;
                    });

                    setData(newData);
                    setAllotmentsSelected(true);
                }
            })
            .finally(() => {
                if (isMounted()) {
                    setLoading(false);
                }
            });
    }

    const editContent = objectId || allotmentsSelected;

    return editContent ? (
        <EditContent {...dataFormHookProps} objectId={objectId} onClose={onClose} onSave={onSave} />
    ) : (
        <SelectAllotments {...dataFormHookProps} onClose={onClose} onSave={handleCreateCutout} />
    );
}
