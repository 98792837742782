import React from 'react';
import { FormControlLabel, Switch, TextField } from '@mui/material';
import { IMaskInput } from 'react-imask';

export const DEFAULT_GPS_FORMAT = 'deg';
export const GPS_FORMAT_DEG = 'deg';
export const GPS_FORMAT_DEC = 'dec';

/*
https://ru.wikipedia.org/wiki/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B5_%D0%BA%D0%BE%D0%BE%D1%80%D0%B4%D0%B8%D0%BD%D0%B0%D1%82%D1%8B

Положительные знаки координат представляются (в большинстве случаев опускаемым) знаком «+» либо буквами:

«N» или «с. ш.» — северная широта,
«E» или «в. д.» — восточная долгота.

Отрицательные знаки координат представляются либо знаком «−», либо буквами:

«S» или «ю. ш.» — южная широта,
«W» или «з. д.» — западная долгота.
*/

// Маски только для положительных значений - мы не используем отрицательные на данный момент

// Широта
const maskDegLatitude = '00°#0′#0,0[0]″N';
const maskDecLatitude = '00,000000';

// Долгота
const maskDegLongitude = '00°#0′#0,0[0]″E';
const maskDecLongitude = '00,000000';

const placeholderChar = '_';
const degRegexp = /^\s*(\d+)[^\d]+(\d+)[^\d]+(\d+,\d+)[^\d]+([SNEW])$/i;

const GpsMaskedInput = React.forwardRef((props, ref) => {
    const { onChange, angleFormat = 'latitude', inputFormat = DEFAULT_GPS_FORMAT, ...other } = props;

    let mask = null;

    if (angleFormat === 'latitude') {
        if (inputFormat === GPS_FORMAT_DEG) {
            mask = maskDegLatitude;
        } else {
            mask = maskDecLatitude;
        }
    } else {
        if (inputFormat === GPS_FORMAT_DEG) {
            mask = maskDegLongitude;
        } else {
            mask = maskDecLongitude;
        }
    }

    return (
        <IMaskInput
            {...other}
            mask={mask}
            placeholderChar={placeholderChar}
            definitions={{ '#': /[0-5]/ }}
            lazy={false}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

export function gpsConvertDecToDeg(srcDec, angleFormat) {
    let dec = gpsDecToNumber(srcDec);

    if (isNaN(dec)) {
        return srcDec;
    }

    let suffix = null;

    if (angleFormat === 'latitude') {
        if (dec < 0) {
            suffix = 'S';
        } else {
            suffix = 'N';
        }
    } else {
        if (dec < 0) {
            suffix = 'W';
        } else {
            suffix = 'E';
        }
    }

    dec = Math.abs(dec);

    let deg = Math.floor(dec);
    let min = Math.floor((dec - deg) * 60);
    let sec = (((dec - deg) * 60 - min) * 60).toFixed(2).replace(/\.(\d)0+$/, '.$1');

    if (deg < 10) {
        deg = '0' + deg;
    }

    if (min < 10) {
        min = '0' + min;
    }

    if (sec < 10) {
        sec = '0' + sec;
    }

    return deg + '°' + min + '′' + sec.toString().replace('.', ',') + '″' + suffix;
}

export function gpsConvertDegToDec(srcDeg) {
    let m = (srcDeg + '').replace(new RegExp(placeholderChar, 'g'), '0').match(degRegexp);

    if (null === m) {
        return srcDeg;
    } else {
        let sign = 1;
        let signKey = m[4];

        if (signKey.toUpperCase() === 'S' || signKey.toUpperCase() === 'W') {
            sign = -1;
        }

        let deg = +m[1];
        let dec = (deg + m[2] / 60 + m[3].replace(',', '.') / 3600).toFixed(6);

        if (deg < 10) {
            dec = '0' + dec;
        }

        return (sign < 0 ? '-' : '') + dec;
    }
}

export function gpsCleanDecValue(srcDec) {
    if (typeof srcDec === 'string') {
        srcDec = srcDec.replace(',', '.').replace(/\s+/, '');
        const [i, d] = srcDec.split('.', 2);
        srcDec =
            (i < 10 ? '0' : '') +
            i.replace(new RegExp(placeholderChar, 'g'), '') +
            (d ? '.' + d.replace(new RegExp(placeholderChar, 'g'), '0') : '');
    }
    return srcDec;
}

export function gpsDecToNumber(srcDec) {
    return +gpsCleanDecValue(srcDec);
}

export function FormGps({
    loading,
    angleFormat = 'latitude',
    inputFormat = DEFAULT_GPS_FORMAT,
    error,
    helperText,
    ...props
}) {
    let errorText = '';

    if (error && error !== true) {
        errorText = error;
        error = true;
    }

    helperText = errorText + (errorText && helperText ? ', ' : '') + (helperText ? helperText : '');

    return (
        <TextField
            disabled={loading}
            {...props}
            error={error}
            helperText={helperText}
            InputProps={{
                inputComponent: GpsMaskedInput,
                ...props.InputProps,
            }}
            inputProps={{
                angleFormat,
                inputFormat,
                ...props.inputProps,
            }}
        />
    );
}

export function FormGpsFormatSwitch({ inputFormat = DEFAULT_GPS_FORMAT, onChange = (format) => null, ...props }) {
    function handleFormatChange(e) {
        onChange(e.target.checked ? GPS_FORMAT_DEC : GPS_FORMAT_DEG);
    }

    return (
        <FormControlLabel
            control={<Switch />}
            checked={inputFormat === GPS_FORMAT_DEC}
            onChange={handleFormatChange}
            label="Цифровой формат"
            {...props}
        />
    );
}
