import React from 'react';

import { TableCell, TableRow, useTheme } from '@mui/material';

import { api } from '../../lib/api';
import { CustomDataTable, useCustomDataTable } from '../../components/CustomDataTable';
import { ForestriesForm } from './Form';

async function rowsLoader(params) {
    return (await api.forestriesList({ ...params }))?.rows;
}

async function rowsDeleter(ids, params) {
    return await api.forestriesDelete({ ...params, ids });
}

function ForestriesList() {
    const theme = useTheme();
    const [editId, setEditId] = React.useState(false);

    const columns = [
        { field: 'name', title: 'Лесничество' },
        {
            field: 'is_district',
            title: 'Участковое',
            type: 'boolean',
            startSortDir: 'desc',
        },
        { field: 'quarters', title: 'Кол-во кварталов', type: 'integer' },
        { field: 'responsible', title: 'Ответственный лесничий' },
        { field: 'municipal', title: 'Муниципальный район' },
    ];

    const { tableProps, tableActions } = useCustomDataTable({ columns, rowsLoader, rowsDeleter });

    let lastSuperiorName = undefined;
    tableProps.rows = tableProps.rows.map((v) => {
        v.renderSuperiorForestryName = false;

        if (typeof lastSuperiorName === 'undefined' || lastSuperiorName !== v.superior_forestry_name) {
            v.renderSuperiorForestryName = true;
            lastSuperiorName = v.superior_forestry_name;
        }

        return v;
    });

    function renderBeforeRow({ row, rowId }) {
        if (row.renderSuperiorForestryName) {
            return (
                <TableRow>
                    <TableCell colSpan={2}></TableCell>
                    <TableCell colSpan={columns.length}>
                        <div
                            style={{
                                ...theme.typography.subtitle1,
                                color: theme.palette.text.secondary,
                                paddingLeft: theme.spacing(5),
                                paddingTop: theme.spacing(2),
                                paddingBottom: theme.spacing(2),
                            }}
                        >
                            <strong>
                                {null !== row.forestry_id ? row.superior_forestry_name : <em>* ГОЛОВНОЕ *</em>}
                            </strong>
                        </div>
                    </TableCell>
                </TableRow>
            );
        } else {
            return null;
        }
    }

    return (
        <div>
            {false !== editId && (
                <ForestriesForm
                    objectId={editId}
                    onSave={() => {
                        setEditId(false);
                        tableActions.loadRows();
                    }}
                    onClose={() => setEditId(false)}
                />
            )}
            <CustomDataTable
                {...tableProps}
                onCreate={() => setEditId(null)}
                onEdit={(id) => setEditId(id)}
                renderBeforeRow={renderBeforeRow}
            />
        </div>
    );
}

export { ForestriesList };
